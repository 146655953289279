.salary-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;
  /* padding: 16px; */
}

@media (min-width: 768px) {
  .salary-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.salary-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
}

.salary-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.salary-header {
  padding: 20px 24px;
  border-bottom: 1px solid #f0f0f0;
}

.salary-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.salary-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.salary-amount {
  font-size: 1.125rem;
  font-weight: 500;
  color: #2563eb;
}

.fixed-pay-badge {
  display: inline-block;
  padding: 6px 12px;
  background-color: #e9f0ff;
  color: #2563eb;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
}

.salary-content {
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}

.salary-content::-webkit-scrollbar {
  width: 6px;
}

.salary-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.salary-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 16px;
  padding: 12px 24px;
  background-color: #f8fafc;
  position: sticky;
  top: 0;
  font-weight: 500;
  color: #64748b;
  font-size: 0.875rem;
}

.table-row {
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
}

.row-content {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 16px;
  align-items: center;
}

.component-cell {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1f2937;
}

.component-cell.blue {
  color: #2563eb;
}

.component-cell svg {
  width: 14px;
  height: 14px;
}

.calculation-cell {
  color: #4b5563;
}

.percentage {
  color: #9ca3af;
  margin-left: 4px;
}

.value-cell {
  text-align: right;
  color: #1f2937;
}

.total-row {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 16px;
  padding: 16px 24px;
  background-color: #f8fafc;
  font-weight: 500;
  color: #1f2937;
  border-top: 1px solid #e5e7eb;
}

.total-row > div:nth-child(3),
.total-row > div:nth-child(4) {
  text-align: right;
}