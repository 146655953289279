.shift-reopen-password-dialog-container{
    width: 38vw;
    padding: 6%;
    display: flex;
    flex-direction: column;
}
.shift-reopen-password-dialog-container h4{
    margin: 0;
}
.shift-reopen-password-dialog-container .shift-reopen-password-input{
    width:98% !important;
    margin: 4% 1%;
}
.shift-reopen-password-dialog-container .shit-reopen-btn{
    align-self: center;
    width: 30% !important;
    margin: 8% 1% 1% 1% !important;
}
.shift-reopen-table-dialog-container{
    width: 60vw;
    padding: 4% 2%;
}
.shift-reopen-table thead{
    background-color: #eee !important;
    border-bottom: 20px solid #fff;
    height: 60px !important;
}
.shift-reopen-table thead tr th{
    background-color: #eee !important;
}
.shift-reopen-table tbody tr td input[type="checkbox"]{
    accent-color: #000;
    cursor: pointer;
}
.shift-reopen-table-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6% 0 0 0;
}
.shift-reopen-confirm-dialog-container{
    width: 35vw;
    padding:3% 2% ;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.shift-reopen-confirm-dialog-container .top-container{
    width: 70px;
    height:70px;
    border: 2px solid red;
    border-radius: 50%;
    margin: 2% 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shift-reopen-confirm-dialog-container h5{
    color: red;
    font-size: 1rem;
    margin: 2% 0;
}
.shift-reopen-confirm-dialog-container .button-container button{
    width: 42% !important;
}
.shift-reopen-confirm-dialog-container .button-container{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6% 0 2% 0;
}
.shift-reopen-success-dialog-container{
    width: 25vw;
    padding:4% 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.shift-reopen-success-dialog-container .icon-container{
    width: 70px;
    height:70px;
    border: 2px solid  #00c14d;
    border-radius: 50%;
    margin: 2% 0;
    display: flex;
    justify-content: center;
    align-items: center;   
}
.shift-reopen-success-dialog-container h5{
    color: #00c14d;
    font-size: 1rem;
}
.shift-reopen-table-dialog-top-container{
    padding: 2% 3%;
    margin: 1% 2%;
    box-shadow: 0 2px 4px rgb(209, 204, 204);
}
.shift-reopen-table-dialog-top-container-section{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.shift-reopen-input{
    margin-left: 0 !important;
}
.shift-reopen-admin-search-container{
    background-color: #eee;
    box-shadow: 0 0px 0px rgb(209, 204, 204);
    height: 40px;
    margin: 1%;
    width: 25%;
    border-radius: 4px;
    display: flex;
    /* justify-content: space-between; */
    position: relative;
}
.shift-reopen-admin-search-container input {
    border: none;
    height: 34px;
    font-size: 0.8rem;
    padding: 4%;
    background-color: #eeeeee;
    width: 100%;
}