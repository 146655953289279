.add-popup-container{
    width: 40vw;
    height: auto;
    padding:4%;
}
.add-popup-container p{
    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
}
.add-popup-container .category-info{
  display: flex;
  font-size: 0.85rem;
}
.add-popup-input-container{
    display: flex;
    justify-content: space-between;
    margin: 4% 0;
}
.add-popup-input-container input{
    background-color: #eee;
    border: none;
    height:48px;
    width: 82%;
    border-radius: 4px;
    font-size: 1rem;
    padding: 2%;
    
}
.add-popup-input-container button{
    background-color: #272a2c;
    height:46px;
    width:12%;
    color: #fff;
    font-size: 1.6rem !important;
    font-weight:500;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}
.add-popup-input-container button:hover{
    background-color: #000;
}
.add-popup-table{
    padding: 1%;
    margin: 1%;
    background-color: #fff;
    max-height:45vh;
    overflow-y: scroll;
}
.add-popup-table table{
  width: 100%;
  border-collapse: collapse;
}
.add-popup-table table thead{
    position: sticky;
    top: -6px;
    background-color: #fff;
    height: 42px;
    z-index: 2;
}
.add-popup-table table thead th{
    /* height: 42px; */
    border-bottom: 1px solid rgb(238, 232, 232);
    font-size: 0.9rem;
}

/* .add-popup-table table thead tr th{
    position: sticky;
    top: -6px;
    background-color: #fff;
    height: 42px;
    border-bottom: 1px solid rgb(199, 195, 195);
  } */
  .add-popup-table table tbody tr td{
    text-align: center;
    font-size: 0.9rem;
    height: 42px;
  }
  .add-popup-table table tbody tr td input[type='checkbox']{
    cursor: pointer;
    accent-color: #000;
  }
   .add-popup-table::-webkit-scrollbar {
    width:4px;
    background-color: #e6e6e6;
  }
 
  
  .add-popup-table::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  .add-popup-button-container{
    display: flex;
    justify-content: center;
    margin: 8% 0 2% 0;
  }
  
