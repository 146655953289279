.costing-method-container{
    display: flex;
    margin: 4% 0 8% 0;
}

.discount-config-checkbox-container{
    display: flex;
}
.discount-config-checkbox-container input[type="checkbox"]{
    width: 25px;
    accent-color: #000;
    cursor: pointer;
}
.discount-config-checkbox-container label{
    font-size: 0.9rem;
    cursor: pointer;
}