.billing-parent-container{
  margin: 10px;
  margin-right: 5px;
  margin-bottom: 0;
}
.billing-main-container{
  display: flex;
  height: calc(100vh - 76px);
}
  .billing-left-first {
    display: flex;
    flex: 1;
    gap:3px;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  .billing-order-type-radio-btn {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 15%;
  }
  
  .billing-left-firstnav-container {
    display: flex;
    width: 100%;
    background-color: white;
    gap: 5px;
    padding: 10px;
    justify-content: flex-start;
    overflow: auto;
    /* margin: 15px 0; */
    margin-top: 15px;
    box-shadow: -1px 0px 4px 0px rgba(128, 128, 128, 0.5);
  }
  .billing-left-firstnav-container::-webkit-scrollbar {
    display: none;
  }
  
  .billing-left-firstnav-container p {
    align-self: center;
    font-size: 0.8rem;
    padding-right: 5%;
    border: 1.5px solid #9f4f4f;
    border-radius: 19px;
    padding: 1px 4px;
    border-color: #898686;
    margin-left: 7px;
    cursor: pointer;
    /* min-width: 60px; */
    text-align: center;
    color: #898686;

  }
  .sub-category-select{
    justify-content: start !important;
    padding: 0!important;
  }
  .selected-p {
    background-color: rgb(9, 151, 23);
    color: #fff!important;
    border: 1px solid #16951e !important;
    box-shadow: 0px 1px 5px rgba(20, 139, 7, 0.7);
  }
  .billing-left-firstnav-container p:hover{
    border-color: rgba(20, 139, 7, 0.7);
    color: rgba(20, 139, 7, 0.7);
  }
  .billing-left-button p {
    /* cursor: pointer; */
    padding: 0;
    /* margin-right: 2%; */
    /* max-width: 70%; */
    color: #024502;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .sub-category-select p{
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid #000000;
    /* margin-right: 2%; */
    color: #024502;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 30px;
    margin: 0;
    margin-left: 5px;
  }
  .billing-left-button p.selected-pos{
    background-color: #09971769;
  }
  .offer-tag img{
    width: 40px;
    height: 30px;
    padding: 6px 0px;
  }



  .card-content {
    width: 24%;
    box-shadow: 2px 2px 5px 0px rgba(128, 128, 128, 0.5);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 5px;
    /* padding-bottom: 0; */
    position: relative;
    max-height: 148px;
    min-width: 120px;
    justify-content: space-between;
  }
  .card-content:hover{
    background-color: #e7e7e7;
  }
.offer-price{
  display: flex;
  align-items: center;
  position: absolute;
  min-width: 40px;
  justify-content: center;
  height: 15px;
  top: 3px;
  right: 3px;
  /* background: linear-gradient(to right, #006400, #00cc00); */
  color: #fff;
  border-radius: 2px;
  font-size: 0.7rem;
}
  .product-image{
    width: 60%;
    height: 60%;
    border-radius: 5px;
    align-self: center;
  }
  .card-image-detail{
    display: flex;
    padding: 7px;
  
  }
  .card-buttons {
    display: flex;
    margin: 10px 0;
  }
  .button-c {
    background-color: white;
    margin-right: 10px;
    padding-inline: 10px;
    border-radius: 8px;
    /* padding: 5px 10px; */
    border: 1px dashed#929395;
    cursor: pointer;
    height: 15px;
    font-size: 0.6rem;
  }
  .billing-left-button{
    width: 100%;
    justify-content: space-between;
    /* padding: 10px 0; */
    display: flex;
    gap: 3px;
    position: relative;
    background-color: #fff;
    margin-top: 5px;
  }
  .billing-card-section{
    border: 1px solid black;
    height: 34px;
    width: 157px;
  }
  .left-second-div{
    height: 26px;
   
    position: relative;
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
  .billing-search-icon{
    color: gray;
  }
  .billing-search-bar{
    width: 22%;
    background-color: white;
    display: flex;
    align-items: center;
    position: relative;
  }
  .bar-code-enabled{
    filter: invert(53%) sepia(69%) saturate(6933%) hue-rotate(194deg) brightness(102%) contrast(98%);
  }
  .left-second-div-arrow{
    position: absolute;
    border: none;
    border-radius: 71%;
    background-color: white;
    cursor: pointer;
  }

  .button-inside-field{
    margin-right: 58px;
    padding: 1%;
      background-color: #e31717;
      border-radius: 7px;
      padding-inline: 7px;
  }
  
  .dashed-line {
    border-bottom: 1px dashed rgb(131, 129, 129);
      margin-top: 10px;
      width: 100%;
      margin-top: 1%;
      margin-bottom: 1%;
  }
  
  .billing-section-head{
    display: flex;
    justify-content: space-between;
      width: 100%;
  }
  .billing-section-head p{
    font-weight: 600;
    margin: 0;
  }
  .billing-input-field-sub{
    display: flex;
      justify-content: space-between;
      height: 35px;
      width: 100%;
  }
   
   .billing-input-field-sub p{
    font-size: smaller;
   }   
   .billing-input-field-credit{
    position: relative;
    align-items: center;
    display: flex;
      width: 100%;
      justify-content: space-between;
  }
  

 
  
  .billing-input-field{
    display: flex;
      width: 100%;
      justify-content: space-between;
  }
  .billing-input-field div.input-div,
  .billing-input-field-credit div.input-div
  {
    width: 47%;
    position: relative;
  }
  .billing-input-field div.input-div input,
  .billing-input-field-credit div.input-div input
  {
    width: 100%;
  }
  .billing-cheque-section{
    width: 68%;
  }
  .billing-input-field input{
    width: 47%;
    height: 29px;
      border: 1px dashed gray;
      text-align: end;
      padding: 0 4px;
  }
  
  .card-details h1 {
    font-size: 0.9rem;
    margin: 0px;
    word-wrap: break-word;
  }
  .card-details p {
    margin: 0px;
    font-size: 0.8rem;
  }
  
 
  .card-image img{
    width: 66px;
    height: 66px;
    border-radius: 4px;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
  }
  .cards::-webkit-scrollbar {
    width: 4px; /* You can change the width as needed */
  }
  
  /* Customize the track (the background of the scrollbar) */
  .cards::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Customize the thumb (the draggable part of the scrollbar) */
  .cards::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
  
  /* Optionally, you can also style the buttons (arrows) at the top and bottom of the scrollbar */
  
  .billing-rightside-container {
    display: flex;
    width: 35%;
    flex-direction: column;
  }
  .billing-single-product-container{
    background-color: #fff;
    padding: 1%;
    width: 24%;
    border-radius: 4px;
    margin: 1% 0%;
    position: relative;
  }
 
  .count-buttons{
    cursor: pointer;
  }
  .billing-left-secoundnav-container {
    display: flex;
    background-color: white;
    height: 40px;
    gap: 0 4px;
    width: auto;
    width: 43%;
    align-items: center;
    flex: 1;
  }

  .billing-btn-2 {
    background-color: #159132;
    min-width: 130px;
  }
  .billing-btn-2 .MuiAutocomplete-root{
    border-left: 2px solid #fff;
  }
  .billing-btn-2 .MuiOutlinedInput-root {
    padding: 2px!important;
  }
  .billing-btn-2 .MuiOutlinedInput-notchedOutline {
    border: none !important;
    padding: 0;
  }

  .billing-btn-2 input{
    font-size: 0.8rem;
    color: #fff;
  }
  .billing-btn-2 input:disabled{
    color: #fff;
    cursor: not-allowed;
  }
  .billing-btn-2 svg{
    color: #fff;
  }
  .circle-button {
   
    color: white !important;
    
    padding-right: 8px;
    min-width: 15px;
  }
  
  .billing-left-thirdnav-container {
    display: flex;
    width: 60%;
    background-color: white;
    padding: 10px;
  }
  .billing-btn-2 span {
    color: green; /* Set the color of the "+" symbol */
    font-size: 0.9rem; /* Set the size of the "+" symbol */

  }
  
  
 
  .payment-m{
    display: flex;
    justify-content: space-between;}
  .search-bar {
    width: 100%;
    height: 96%;
    border: none;
    background-color: #fff;
  }
  
  .billing-clear-button{
    border: none;
      color: #fff;
      font-size: 0.8rem;
      width: 20%;
      height: 24px;
      border-radius: 4px;
      cursor: pointer;
      background-color: red;
  }
  .billing-placeOrder-button{
    border: none;
      color: #fff;
      font-size: 0.8rem;
      width: 50%;
      height: 38px;
      border-radius: 4px;
      cursor: pointer;
      background-color: #139109;
      box-shadow: 0px 1px 7px rgb(56 141 23 / 70%);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  .billing-card-image img {
    width: 40px;
    height: 40px;
    padding: 3px 5px;
      border-radius: 27%;
   
  }
  
  .billing-card-details p {
    font-size: 10px;
    margin: 0px;
  }
  .card-details{
    margin-top: 7px;
  }
  .cart-buttons-ctn{
    display: flex;
    justify-content: center;
    width: 25%;
  }

  .cart-buttons-ctn input{
    border: none;
    width: 60%;
    text-align: center;
  }
  .btn-decrease {
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: white;
  }
  .btn-increase {
    cursor: pointer;
    background-color: black;
    color: white;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .btn-delete {
    margin-right: 12px;
    border: none;
    background: transparent;
    color: red;
    font-size: 26px;
  }
  
  
  
  
  .billing-card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 0px; 
    margin-bottom: 4px;
    padding: 2px;
    box-shadow: 2px 2px 5px 0px rgba(128, 128, 128, 0.5);
    cursor: pointer;
    position: relative;
  }
  .billing-card-content p.combo-list{
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 45px;
    font-size: 0.8rem;
    color: #978a8a;
  }
  
  .payment-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* height: 356px; */
    height: auto;
    padding: 10px;
    background-color: white;
    gap: 6px;
  }
  
  .payment-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding-bottom: 34px;
    height: 10%;
    
  }
  
  .input-container {
    flex: 1;
    margin-left: 10px; /* Adjust the margin to control the spacing */
  }
  
  .clear-amount-box {
    border: 2px dashed  rgb(217, 97, 18);
    padding-bottom: 7%;
      padding-top: 1%;
  }
  
  .billing-amount-field-s{
    background-color: #bdbaba;
      width: 50%;
      display: flex;
      justify-content: flex-end;
  }
  .billing-amount-field input{
    border: none;
  }
  .billing-amount-field{
    border: 2px dashed gray;
      width: 50%;
      display: flex;
      justify-content: flex-end;
      padding: 3%;
  }
  .billing-amount-field p{
    padding: 2%;
  }
 
  .billing-input-field-button{
      position: absolute;
      background-color: gray;
      /*margin-bottom: -7%;
      *//*margin-top: -1%;
      */border-radius:15%;
      padding-inline: 1%;
      padding-right: 1%;
      margin-left: 3px;
      font-size: small;
      -webkit-border-radius: 22%;
      -moz-border-radius: 22%;
      -ms-border-radius: 22%;
      -o-border-radius: 22%;
  }
  .billing-input-field-add{
    position: absolute;
    left: 5%;
    cursor: pointer;
    top: 8px;
    background: #0a0909;
    border-radius: 4px;
    padding: 0 4px;
    height: 20px;
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .billing-input-field-add2{
      position: absolute;
      /* right: 33%; */
      cursor: pointer;
      left: 5%;
      top: 8px;
      background: #f51717;
      border-radius: 4px;
      padding: 0 4px;
      height: 20px;
      color: #fff;
      font-size: 0.7rem;
      font-weight: 600;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
  
  
  
  .total-amount {
    display: flex;
    padding: 10px;
    background-color: #e1e4e5;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }
  


  .total-a {
    text-align: right;
    font-size: small;
  }
  .total {
    display: flex;
    flex-direction: column;
  }
  .payment-m h1 {
    margin: 0;
    font-weight: 500;
    padding: 1px;
    font-size: small;
  }
  #paymentMethodDropdown {
    background-color: white;
    color: black;
    border: none;
    width: 81px;
  }
  .cash-collect {
    background: white;
    padding: 10px;
  
  }
 
  .billing-last-button{
    width: 100%;
    justify-content: space-between;
      display: flex;
      margin-top: 5px;
      /* margin-top: auto; */
  }
  .clear-amount-box input{
    border: 2px dashed red;
    height: fit-content;
  }
  .billing-selectNew{
    width: 30%;
  }
  .billing-selectNew .MuiInputBase-input{
    font-size: 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .billing-selectNew  div.MuiOutlinedInput-root {
    background-color: white !important;
    margin-bottom: 1px;
  }
  .cash-collect{
    width: 65%;
      height: 29px;
      margin-top: 4px;
      padding-inline: 10px;
  }
  .billing-left-container{
  flex: 1;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
}



.billing-left-card {
  margin-right: 10px;
  width: 214px;
  height: 85px;
  background-color: #ffffff;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}
.billing-right-card{
  /* height: 28%; */
    border: 1px solid #ccc;
   margin-bottom: 1%;
    justify-content: space-between;
    border: none;
    overflow-y: auto;
    position: relative;
}
.billing-right-card::-webkit-scrollbar {
  display: none;
}
.button-inside-field{
  margin-right: 58px;
  padding: 1%;
    background-color: #e31717;
    border-radius: 7px;
    padding-inline: 7px;
}
.button-inside-field-r{
  margin-right: 58px;
  padding: 1%;
    background-color: #737171;
    border-radius: 7px;
    padding-inline: 7px;
}
.dashed-line {
  border-bottom: 1px dashed rgb(131, 129, 129);
    margin-top: 10px;
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.billing-section-head{
  display: flex;
  justify-content: space-between;
    width: 100%;
}
.billing-section-head p{
  font-weight: 600;
  margin: 0;
}
.billing-input-field-sub{
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  width: 100%;
}
 .billing-input-field-sub input{
  width: 47%;
  height: 29px;
    border: none;
    background-color: #c7c5c5;
    text-align: end;
    padding: 0 4px;
 }   
 .billing-input-field-sub p{
  font-size: smaller;
  margin: 0;
 }   
 .billing-input-field-credit{
  align-items: center;
  display: flex;
    width: 100%;
    justify-content: space-between;
}

.billing-input-field-credit p{
 font-size: 0.9rem;
 font-weight: 500;
 color:  rgb(248, 19, 19);
 margin: 0;
}
.billing-input-field-credit input{
  width: 47%;
  height: 35px;
    border: none;
    border: 1px dashed rgb(248, 19, 19);
    
    text-align: end;
    padding: 0 4px;
 }   

.billing-input-field{
  align-items: center;
  position: relative;
  display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.billing-input-field p{
  font-size: 0.9rem;
    font-weight: 500;
    margin: 0;
}
.billing-input-field input{
  width: 47%;
  height: 35px;
    border: 1px dashed gray;
    text-align: end;
    padding: 0 4px;
}
.card-details p {
  margin: 0px;
}

.card-image {
  height: 100px;
  margin-right: 10px;
}
.cards {
  display: flex;
  overflow-y: auto;
  position: relative;
  /* max-height: 69vh; */
  align-content: start;
  height: 90%;
  margin: 5px 0;
  gap: 1%;
  background-color: #fff;
  padding: 10px;
  flex: 1 1;
}
.billing-rightside-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
 
.top-bar-buttons{
  font-size: 0.8rem;
  font-weight: 600;
  color: rgb(255, 255, 255);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  border: none;
  
  width: calc(25% - 1px);
  margin-right: 1px;
  flex: 1;

}

.top-bar-buttons:hover{
  background-color: #3f657e!important;
  color: #fff!important;
}
.top-bar-buttons:last-child {
  margin-right: 0;
}
.billing-btn-3 {
  background-color: rgb(21 128 145);
}
.billing-btn-4 {
  background-color: #cc5200;
}
.billing-btn-table{
  background-color: rgb(0 6 193);
}

.billing-left-thirdnav-container {
  display: flex;
  width: 60%;
  background-color: white;
  padding: 10px;
}

.billing-array {
  
  width: 100%;
  overflow-x: auto;
  display: flex;
  border: none;
  gap: 5px;
  scroll-behavior: smooth;
  padding: 0 25px;
}
.billing-array::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.billing-array::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 18px;
}
.billing-array::-webkit-scrollbar-track {
  background-color: #ffffff00;
}
.payment-m{
  display: flex;
  justify-content: space-between;}

.billing-clear-button{
  border: none;
    color: #fff;
    font-size: 0.8rem;
    width: 20%;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    background-color: red;
}
.buttons-rewarded{
  background-color: #2179b5!important;
}
.billing-placeOrder-button{
  border: none;
    color: #fff;
    font-size: 0.8rem;
    width: 35%;
    height: 38px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #139109;
}
.billing-payment-button{
  border: none;
    color: #fff;
    font-size: 0.8rem;
    width: 35%;
    height: 38px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #3100ff;
}
.billing-card-image img {
  width: 40px;
  height: 40px;
  padding: 3px 5px;
    border-radius: 27%;
 
}

.billing-card-details h1 {
  font-size: 0.8rem;
  margin: 0px;
  word-wrap: break-word;
}
.billing-card-details p {
  font-size: 10px;
  margin: 0px;
}
.billing-card-details {
  /* margin-right: 12px; */
  width: 20%;
}
.btn-decrease {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: white;
}
.btn-increase {
 
  background-color: black;
  color: white;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.btn-delete {
  margin-right: 12px;
  border: none;
  background: transparent;
  color: red;
  font-size: 26px;
}




.input-container {
  flex: 1;
  margin-left: 10px; /* Adjust the margin to control the spacing */
}

.clear-amount-box {
  border: 2px dashed  rgb(217, 97, 18);
  padding-bottom: 7%;
    padding-top: 1%;
}

.billing-amount-field-s{
  background-color: #bdbaba;
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.billing-amount-field input{
  border: none;
}
.billing-amount-field{
  border: 2px dashed gray;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding: 3%;
}
.billing-amount-field p{
  padding: 2%;
}

.billing-input-field-payment{
  width: 68%;
    height: 30px;
    background-color: white;
    border-radius: 5px;
    margin-top: 2px;
    display: flex;
    align-items: center;
}
.billing-input-field-cheque{
  display: flex;
    justify-content:space-between;
    width: 100%;
  height: 36px;
    border-radius: 3px;
    margin-bottom: 2px;
    margin-left: 3px;
}
.billing-cheque-number{
  width: 52%;
    background-color: white;
    border-radius: 4px;
}
.billing-cheque-date{
  background-color: white;
    width: 47%;
    border-radius: 4px;
}
.billing-input-field-cheque-amount{
  display: flex;
  justify-content: space-between;
  width: 75%;
  height: 30px;
  border-radius: 3px;
  margin-bottom: 2px;
  margin-top: 2px;
  margin-left: 3px;
  background-color: white;
}
.billing-input-field-cheque-amount input{
  padding-inline: 8px;
    width: 100%;
    border: none;
    border-radius: 4px;
}


.billing-selectNew-card{
  width: 153px;
}
.billing-input-field-cheque input{
  width: 100%;
 
  border: none;
  background: transparent;
}
.billing-input-field-payment p{
  margin: 0;
  padding-inline: 10px;
}
.billing-input-field-button{
    position: absolute;
    background-color: gray;
    /*margin-bottom: -7%;
    *//*margin-top: -1%;
    */border-radius:15%;
    padding-inline: 1%;
    padding-right: 1%;
    margin-left: 3px;
    font-size: small;
    -webkit-border-radius: 22%;
    -moz-border-radius: 22%;
    -ms-border-radius: 22%;
    -o-border-radius: 22%;
}

.billing-card-number{
  /* width: 30%; */
  width: 46%;
}
.billing-card-number input{
    width: 100%;
    height: 30px;
    /* margin-top: 4px; */
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.total h1 {
  margin: 0px;
  font-size: 1rem;
}
.total p {
  margin: 0px;
  font-size: 0.8rem;
  font-weight: 500;
  
}
.total-a h1 {
  margin: 0px;
  font-size: 0.9rem;
}
.total-a p {
  font-weight: 300;
  font-size: 0.8rem;
  margin: 0;
}
.total-a {
  text-align: right;
  font-size: small;
}
.total {
  display: flex;
  flex-direction: column;
}

#paymentMethodDropdown {
  background-color: white;
  color: black;
  border: none;
  width: 81px;
}


.billing-Hold-button {
  border: none;
    color: #fff;
    font-size: 0.8rem!important;
    width: 14%;
    height: 38px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f39b04!important;
    box-shadow: 0px 1px 7px rgb(237 123 64 / 70%);
    transition: transform 0.2s, box-shadow 0.2s;
}
.billing-Hold-button:hover{
  background-color: #ffdfa8 !important;
}
.billing-clear-button {
  border: none;
    color: #fff;
    font-size: 0.8rem;
    width: 14%;
    height: 38px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f31704;
}

.clear-amount-box input{
  border: 2px dashed red;
  height: fit-content;
}

.cash-collect{
  width: 65%;
    height: 29px;
    margin-top: 4px;
    padding-inline: 10px;
    background: white;
  padding: 10px;
}
.cash-collect-checked{
  width: 65%;
    height: 57px;
    margin-top: 4px;
    padding-inline: 10px;
    background: white;
  padding: 10px;
}

.billing-customer-ctn{
  width:45vw;
  padding: 2% 6%;
}
.billing-customer-ctn h4{
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 2px dashed rgb(163, 162, 162);
}
.billing-customer-ctn-fields{
  display: flex;
  flex-wrap: wrap;
}
.billing-customer-ctn-fields .new-global-single-input{
  width: 46%;
}
.billing-customer-ctn-fields .global-single-input {
  width: 47%;
  align-items: flex-end;
}
.billing-customer-ctn-fields .global-single-input p{
  font-size: 0.7rem;
  margin-right: 4%;
}
.billing-type-right-card-ctn{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2px;
  justify-content: space-between;
  width: 75%;
}
.billing-input-field-payment p{
  font-size: 0.8rem;
  padding-left: 5px;
}
.barcode-input{
  position: absolute;
  opacity: 0;
  width:1px;
  right: 0;
}
.new-billing-order-type-switch{
  display: flex;
  background-color: #fff;
  padding: 0 5px;
  min-width: 150px;
  max-width: 175px;
  width: 15%;
  justify-content: space-evenly;
  position: relative;
}
.new-billing-order-type-switch .switch-bill-type{
  display: flex;
  padding: 4px 8px;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.billing-new-category-single{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px 0;
  width: 90px;
  cursor: pointer;
  padding: 4px;
  margin: 10px 0;
  justify-content: center;
  min-width: 100px;
}
.billing-new-category-single img{
  width: 65px;
  height: 65px;
}
.billing-new-category-single p{
  margin: 0;
  border: none;
  font-size: 0.9rem;
  text-align: center;
}
.selected-category-single{
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 1px 2px 2px 2px rgba(128, 128, 128, 0.5);
}
button.new-billing-category-slide{
  position: absolute;
  box-shadow: 2px 2px 5px 0px rgba(128, 128, 128, 0.5);
  background-color: #fff;
  border: none;
  padding: 15px 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.card-buttons .MuiOutlinedInput-notchedOutline{
  border: 2px dashed #74b89d;
}
.card-buttons .MuiInputBase-root{
  height: 27px;
  padding: 0!important;
  border: none;
}
.card-buttons input{
  padding: 2px 10px;
    font-size: 0.8rem;
}
.billing-card-details-add-on-btn{
  color: #088051;
  cursor: pointer;
  font-weight: 600;
}

.billing-cart-total-price{
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 100px;
  align-items: flex-end;
}
.billing-cart-total-price p{
  font-size: 0.8rem;
  margin: 0;
  font-weight: 600;
}
.billing-cart-total-price p.sub{
  font-size: 0.7rem;
  color: gray;
  font-weight: 400;
}
.billing-cart-total-price .sub input{
  font-size: 0.7rem;
  color: gray;
  border: none;
  text-align: right;
}

.billing-rightside-container .subtotal-amount-ctn{
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  border: 0.4px solid #676060;
}
.billing-rightside-container .subtotal-amount-ctn p{
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
}
.billing-rightside-container .subtotal-amount-ctn input{
  border: none;
  background-color: #edf2f9;
  padding: 7px 10px;
  text-align: end;
  width: 65%;
}

.billing-tables-ctn{
  width: 50vw;
  height: 70vh;
  position: relative;
}
.billing-table-drop-down-ctn .MuiInputBase-root{
  background-color: #ffffff !important;
  border: 1px solid #000 !important;
}
.billing-table-drop-down-ctn .global-single-input input{
  background-color: #ffffff !important;
  border: 1px solid #000;
}
.billing-table-drop-down-ctn .MuiInputBase-root input{
  background-color: transparent!important;
  border: none!important;
}
.billing-tables-ctn .new-billing-order-type-switch{
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #000;
  padding-top: 5px;
  gap: 15px;
}
.billing-tables-ctn .new-billing-order-type-switch p{
  color: #646464;
  
  width: 100px;
  text-align: center;
}
.billing-tables-ctn .new-billing-order-type-switch .active{
  color: #000000 !important;
  border-bottom: 4px solid #000000;
  font-size: 1rem;
  font-weight: 600;
}
.billing-tables-ctn .new-billing-order-type-switch .active p{
  color: #000000 !important;
  font-size: 1rem;
}

.billing-table-drop-down-ctn{
  padding: 0% 5%;
  height: 85%;
}
.billing-table-drop-down-ctn .global-single-input{
  width: 40%;
}
.billing-table-drop-down-ctn .view-order-search-container{
  width: 30%;
  position: relative;
  margin: 1%;
  align-self: flex-end;
  height: 30px;
  border: 1px solid #000
}
.billing-table-drop-down-ctn .view-order-search-container input{
  height: 28px!important;
}
.billing-table-lists{
  display: flex;
  padding: 1%;
  padding-top: 2%;
  flex-wrap: wrap;
  max-height: 80%;
  overflow-y: auto;
  gap: 8px;
  justify-content: space-between;
}
.billing-table-lists::-webkit-scrollbar {
  width: 4px;
}

.billing-table-lists::-webkit-scrollbar-thumb {
  background-color: black; 
  border-radius: 6px; 
}

.billing-table-lists::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}
.table-card{
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 2px 1px rgba(128, 128, 128, 0.5);
  border-radius: 4px;
  padding: 8px;
  gap: 6px;
  width: 23%;
  /* min-width: 150px; */
}
.table-card-head p{
  margin: 0;
  font-weight: 600;
}
.table-card  .global-single-input{
  width: 100%;
  margin: 0;
}
.table-card .global-single-input p{
  font-size: 0.7rem;
  color: grey;
}
.table-card .global-single-input input{
  background-color: #fff;
  box-shadow:2px 2px 3px 1px rgba(128, 128, 128, 0.5);
  height: 25px;
  border-radius: 3px;
  padding-left: 15px;
  border: none !important;
}
.table-card .available-chair{
  font-size: 0.8rem;
  color: #007a08;
  margin: 2px 0;

}

.table-btn-container{
  /* position: absolute; */
  width: 100%;

  /* bottom: 5%; */
}
.table-btn-container .btn-secondary-outlined{
  background-color: #fff;
}


.table-card .order-price{
  margin: 0;
  font-size: 0.7rem;
}
.table-card .order-status{
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
}

.center-div{
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column{
  display: flex;
  flex-direction: column;
}

.cart-table-top{
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px dashed gray ;
  padding: 0 10px;
  gap: 25px;
  font-weight: 600;
  min-height: 35px;
}
.cart-table-top p{
  margin: 0;
  font-size: 0.8rem;
  color: blue;
}
.cart-table-top button{
  margin-left: auto;
  height: 30px;
  background-color: #4eb555;
}


.billing-keypad-section{
  background-color: #fff;
  border: 0.4px solid #676060;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.billing-keypad-section .billing-input-section{
  width: 75%;
}
.billing-keypad-section .billing-input-section button{
  width: 30%;
  height: 40px;
}
.billing-keypad-section .keypad-back-btn{
  /* position: absolute; */
  width: 50% !important;
  left: calc(60% + 20px);
  background-color: #000;
}
.billing-keypad-section .keypad-back-btn:hover{
  background-color: #2e1010;
}
.billing-keypad-section .billing-input-section button.keypad-qty-btn{
  background-color: #c9c9c9;
  font-size: 0.8rem !important;
}
.billing-keypad-section .billing-input-section button.keypad-qty-btn:hover{
  background-color: #478045;

}
.billing-keypad-section .billing-input-section button.keypad-qty-btn-enable:hover{
  cursor: not-allowed;
}
.billing-keypad-buttons{
  display: flex;
  flex-direction: column;
  flex: 1;
}
.buttons-disabled{
  background-color: #bbbbbb!important;
  cursor: not-allowed;
}
.billing-keypad-buttons button {
  margin: 5px;
  padding: 10px;
  font-size: 0.8rem!important;
  text-align: center;
  width: calc(100% - 10px) !important;
  height: 90px;
  line-height: unset;
  box-shadow: -1px 0px 4px 0px rgba(128, 128, 128, 0.5);
}
.billing-keypad-buttons button:hover {
  background-color: #d7caca;
}
.billing-keypad-section button.disabled-hold{
  background-color: #f8d19e;
}
.billing-keypad-buttons button.billing-clear-button {
  height: 40px;
}
.billing-keypad-buttons button.disabled-payment{
  background-color: #90c68d;
  cursor: not-allowed;
}
.billing-keypad-buttons button.disabled-order{
  background-color: #c4c1e7;
  cursor: not-allowed;
}
.billing-keypad-buttons button.disabled-refund{
  background-color: #ffabab;
  cursor: not-allowed;
}
.billing-main-container .flex-column{
  width: 30%;
  /* max-width: 380px; */
}
.flex-row{
  display: flex;
  flex-direction: row;
}

.billing-confirm-order-dialog{
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
.billing-confirm-order-dialog p{
  margin: 0;
}
.billing-confirm-order-dialog p.head{
 color: #139109;
}
.billing-confirm-order-dialog .justify-center{
 margin-top: 10px;
}
.billing-confirm-order-dialog button.btn{
  width: 75px;
  height: 30px;
  font-size: 0.8rem;
}
.billing-confirm-order-dialog button:hover{
  background-color: #bbf398!important;
  color: #fff;
}

.special-items{
  display: flex;
  flex-direction: column;
  width: 24%;
  max-width: 300px;
}
.special-items .top{
  background-color: #efefef;
  padding: 7px;
  cursor: pointer;
}
.special-items .top p{
  font-size: 0.9rem;
  margin: 0;
  font-weight: 600;
}

.veg-selection{
  display: flex;
  align-items: center;
}
.veg-selection p{
  width: 50%;
  margin: 0;
  text-align: center;
  padding: 4px 0;
  border: 1px solid #000;
  font-size: 0.9rem;
  cursor: pointer;
}

.category-list{
  display: flex;
  flex-direction: column;
  background-color: #795547;
  padding: 7px 0;
}

.category-list-menu{
  display: flex;
  flex-direction: column;
  /* padding-top: 10px; */
  background-color: #e7e7e7;
  height: 100%;
  overflow-y: auto;
  direction: rtl;
  padding: 10px 15px;
  gap: 10px ;
}
.category-list-menu .tab{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  padding: 7px 20px;
  color: #000;
  border: 1px solid #fff;
  direction: ltr;
  cursor: pointer;
}
.category-list-menu .tab:hover{
  background-color: #8f8f8f;
  color: #fff !important;
}
.category-list-menu .selected{
  background-color: #1a0800;
}
.category-list-menu .selected:hover{
  background-color: #603624;
}
.products-list{
  display: flex;
  overflow-y: hidden;
  position: relative;
  height: 69vh;
  margin-top: 5px;
  gap: 1%;
  background-color: #fff;
}


.category-list-menu::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}

.category-list-menu::-webkit-scrollbar-thumb {
  background-color: #827b7b;
}

.category-list-menu::-webkit-scrollbar-track {
  background-color: #b7b0ae;
}

.new-billing-order-type-switch .aggregate-ctn{
  width: 200px;
  position: absolute;
  top: 130%;
  left: 40%;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  padding: 7px 10px;
  color: #fff;
  cursor: pointer;
  background-color: #795547;
  z-index: 10;
  box-shadow: 2px 3px 4px #4c3d3d;
}
.new-billing-order-type-switch .aggregate-ctn div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  padding: 7px 10px;
  color: #fff;
  border-bottom: 1px solid #fff;
  direction: ltr;
  cursor: pointer;
}

.new-billing-order-type-switch .aggregate-ctn::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #795547;
  top: -10px;
  content: "";
  height: 0;
  left: 30%;
  margin-left: -24px;
  position: absolute;
  width: 0;
}

.invoice-print-icon-container{
  position: fixed;
  top: 87%;
  left: 50%;
  transform: translateX(-50%);
}
.invoice-print-icon-container button{
  width: 65px;
  height: 65px;
}
.invoice-print-icon-container button:hover{
  background-color: #aea4a4;
}

.MuiPaper-root::-webkit-scrollbar{
  width: 9px; 
  border-radius: 6px;
}

.MuiPaper-root::-webkit-scrollbar-track {
background-color: #ffffff; 
}
  
.MuiPaper-root::-webkit-scrollbar-thumb {
background-color: #888;
}