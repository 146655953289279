.billing-calculator {
    width: 96%;
    padding: 4px;
    margin: 2%;
    border-radius: 2px;
    background-color: #fff;
    align-self: center;
  }
  
  .billing-display {
    margin-bottom: 0px;
  }
  .billing-display input{
    height: 35px;
    text-align: end;
    width: 100%;
    border: 2px solid #cacaca;
    background-color: transparent;
    font-size: 1rem;
  }
  
  .billing-input-section {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: space-between;
  }
  
  .billing-input-section button {
    width: 23%;
    margin: 5px;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    background-color: #37474f;
    color: #fff;
    border: none;
  }
  .billing-input-section button.white-btn {

    padding: 10px;
    background-color: #fff;
    color: #37474f;
    font-weight: 600;
  }
  .billing-input-section button.black-btn {

    padding: 10px;
    background-color: #000;
    color: #fff;
  }
  
  .billing-input-section button.operator,
  .billing-clear {
    background-color: #ff9a8b;
  }
  
  .billing-input-section button.number,
  .billing-percent,
  .billing-root {
    background-color: #f0f0f0;
  }
  
  .billing-input-section button.equal {
    width: 48%; /* The equal button takes up almost half the space */
    background-color: #e85a4f;
    color: #fff;
  }
  
  .billing-input-section button:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  
  .billing-input-section .row{
    display: flex;
    position: relative;
  }