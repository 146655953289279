
  /* styles.css */

/* Container for the tabs */
.custom-tabs {
    width: 100%;
  }
  
  /* Button styles for tabs */
  .custom-tab {
    padding: 0.5rem 1rem; /* Adjust padding */
    font-size: 0.875rem; /* Font size */
    font-weight: 500; /* Medium font weight */
    border: none; /* No border */
    cursor: pointer; /* Pointer on hover */
    transition: all 0.2s ease; /* Smooth transition */
    background: none; /* No background */
  }
  
  /* Active tab styles */
  .custom-tab.active {
    color: #000000; /* Blue color */
    border-bottom: 3px solid #000000; /* Blue border */
    border-radius: 0;
  }
  
  /* Inactive tab styles */
  .custom-tab:not(.active) {
    color: #6b7280; /* Gray color */
    border-bottom: 2px solid transparent; /* No border */
  }
  
  /* Hover effect for inactive tabs */
  .custom-tab:not(.active):hover {
    color: #000000; /* Blue on hover */
    /* border-bottom: 2px solid #000000;  */
  }
  
  /* Tab panel styles */
  .custom-tab-panel {
    margin-top: 2rem; /* Space between tabs and content */
  }
  
  .tab-wrapper-py{
    border-bottom: 1px solid gray;
  }