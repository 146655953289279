.ant-dropdown{
    z-index: 1301;
    padding: 0 6px;
}


.pos-billing-items-container{
    width:65vw;
    min-width:740px;
    min-height:60vh;
    padding: 0 5px;
  }
  
.pos-billing-items-container h2{
    padding: 5px 0;
    padding-left: 20px;
    margin: 0;
    width: 100%;
    background-color: #e7e7e7;
  }

.pos-billing-items-container table th.black-th{
  background-color: black;
  color: #fff;
  border-start-start-radius: 0!important;
}
.pos-billing-items-container table th.other-th{
  background-color: #064562;
  color: #fff;
}
.pos-billing-items-container .ant-table-body{
  background-color: #e7e7e7;
  overflow-y: auto!important;
}

.ant-table-body::-webkit-scrollbar {
  width: 6px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #e3e1e1;
  border-radius: 6px;
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
:where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-filter-trigger{
  color: #fff;
}
:where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #cfcfcf;
  color: #064562;
}
:where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-thead th.ant-table-column-sort{
  background: #cfcfcf;

}
.ant-table-column-sorter{
  color:#fff!important;
}


/* billing loader css */
@keyframes loader_5191 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.billing-loader-effect .square {
  background: #ddd;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
}

.billing-loader-effect #sq1 {
  margin-top: -25px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 0s infinite alternate;
}

.billing-loader-effect #sq2 {
  margin-top: -25px;
  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
}

.billing-loader-effect #sq3 {
  margin-top: -25px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 150ms infinite;
}

.billing-loader-effect #sq4 {
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 225ms infinite;
}

.billing-loader-effect #sq5 {
  animation: loader_5191 675ms ease-in-out 300ms infinite;
}

.billing-loader-effect #sq6 {
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 375ms infinite;
}

.billing-loader-effect #sq7 {
  margin-top: 15px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 450ms infinite;
}

.billing-loader-effect #sq8 {
  margin-top: 15px;
  animation: loader_5191 675ms ease-in-out 525ms infinite;
}

.billing-loader-effect #sq9 {
  margin-top: 15px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 600ms infinite;
}

.payment-table-div table td input.input-table {
  width: 100%;
  padding: 5px 7px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
  max-width: 90px;
}

.payment-table-div table td input.input-table:hover {
  border: 2px solid lightgrey;
  box-shadow: 0px 0px 20px -17px;
}

.payment-table-div table td input.input-table:active {
  transform: scale(0.95);
}

.payment-table-div table td input.input-table:focus {
  border: 2px solid grey;
}

.cards-rewards{
  overflow-y: auto;
  position: relative;
  width: 100%;
  height: 90%;
  gap: 1%;
  background-color: #fff;
  padding: 10px;
  flex-wrap: nowrap;
}

.cards-rewards .card-content{
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: #e7e7e7;
}
.cards-rewards .card-content:hover{
  background-color: #dadada;
}
.cards-rewards .card-content .card-details{
  margin: 0;
  gap: 15px;
}

.cards-rewards .card-content img{
  width: 60px;
  height: 60px;
}