.name-ctn-section{
    display: flex;
    width: 98%;
    margin: 1%;
    flex-wrap: wrap;
}


.name-ctn-section .global-single-input{
    font-size: 0.7rem;
    font-weight: 500;
    width: 19%;
}
.name-ctn-section-left{
    width: 40%;
}
.name-ctn-section-left .global-single-input{
    width: 50%;
}
.name-ctn-section-right{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 60%;
}
.name-ctn-section-right .global-single-input{
    width: 30%;
}
/* .add-table-selection{

} */
.rewards-add-rewards-ctn-top-menu{
    display: flex;
}
.accounts-table-toggle{
    box-shadow: none;
    display: flex;
    /* margin-bottom: 2%; */
}
.accounts-table-toggle .tab-menu-button{
    padding: 9px;
}
.other-info-ctn{
    width: 60%;
    align-items: flex-end;
    padding: 1%;
}


.other-info-ctn .btn-primary{
    width: 25%;
}
.other-info-ctn .global-single-input{
    width: 35%;
}

.table-total-right-section{
    align-self: flex-end;
    width: 100%;
    margin: 1% 0;
    margin-bottom: 0;
}

.table-total-right-section1{
    display: flex;
}

.table-total-right-section2{
    align-self: flex-start;
    width: 100%;
    margin: 1% 0;
    margin-bottom: 0;

}

.table-total-right-section div{
    display: flex;
    gap: 4px;
    justify-content: flex-end;
}
.table-total-right-section p{
    margin: 0;
    font-size: 0.9rem;
    width: 88%;
    text-align: end;
}
.table-total-right-section span{
    font-size: 0.9rem;
    width: 12%;
    font-weight: 600;

}

.white-bg-two-section{
    width: 100%;
    /* margin: 1%; */
    display: flex;
    /* gap: 5px; */
    justify-content: space-between;
}
.white-bg-two-section .global-white-bg-container{
    width: 49.8%;
    margin: 0%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5%;
}
.white-bg-two-section .global-white-bg-container .global-single-input{
    width: 31%;
}

.vendor-check-box-ctn{
    position: absolute;
    left: 41%;
    top: 8%;
    display: flex;
    gap: 6px;
    align-items: center;
}
.vendor-check-box-ctn label{
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}


.CreateReqDisc{
    display: flex;
    align-items: center;
}

.CreateReqDiscInput{
    width: 20%;
    border: none;
    background-color: #eee;
    /* font-size: 1rem; */
    font-size: 0.8rem;
    height: 30px;
    padding: 8px;
    border-radius: 4px;
    margin: 6px;
}

.payment-popup{
    display: flex;
    flex-direction: column;
    padding: 5%;
}
.payment-popup h4{
    width: auto;
    margin-top: 0;
}
.payment-popup .global-single-input{
    width: 44%;
}
.payment-popup .global-input-field-container{
    flex-wrap: wrap;
    justify-content: space-between;
}
.subtotal-ctn{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}
.subtotal-ctn input{
    width: 90%;
    border: none;
    border-bottom: 1px solid rgb(182, 182, 182);
    text-align: end;
}
.subtotal-ctn-left{
    text-align: end;
    /* width: 85px; */
    width: auto;
    color: grey;
}
.subtotal-ctn-right{
    width: 80px;
    text-align: right;
}


.new-global-single-input .balanceAmountdiv{
    /* border: 2px solid orange; */
    margin-top: 5%;
    margin-left: 10%;
}

.balanceAmountdiv .paydiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
}