



.div_data_container{
    display: flex;
    justify-content: space-between;
    padding-inline: 10px;
}
.button-div{
    padding: 46px 0px 0px 0px;
}
.button-div button{
    padding: 7px;
    border: none;
    padding-inline: 20px
}
.selected-button {
    background-color: rgb(139, 138, 138);
    border-bottom: 3px solid black!important;
  
  }
 