.log-settings-container{
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    padding: 2%;
    min-height: 85vh;
    height: auto;
    flex-direction: column;
}
.log-settings-container-top{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50px;
}