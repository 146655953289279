.profile-info-container {
  padding: 3% 5%;
  background-color: #fff;
  margin: 1% 0;
  border-radius: 8px;
}
.profile-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-input-container p {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  /* width: 20%; */
  /* text-align: right; */
}
.profile-input-container input {
  width: 65%!important;
  height: 40px;
  border: 1px solid rgb(206, 199, 199);
  border-radius: 4px;
  font-size: 1rem;
  padding: 1%;
  margin: 2% 0;
}
.profile-input-container input:hover {
  border-color: #000;
}
.profile-input-container input.MuiAutocomplete-input  {
  border: 0;
  margin: 0;
  height: 0px;
}
.auto-complete-country .MuiOutlinedInput-root  {
  width: 100%!important;
}
.profile-input-container .MuiAutocomplete-root  {
  width: 65%;
}
.profile-input-container-disable{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-input-container-disable p{
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.profile-input-container-disable input{
  cursor: not-allowed;
  width: 65%;
  height: 40px;
  border: 1px solid rgb(206, 199, 199);
  border-radius: 4px;
  font-size: 1rem;
  padding: 1%;
  margin: 2% 0;
  color: #c5c3c3;
}
.profile-input-container-disable input:hover{
  border-color: rgb(206, 199, 199);
}
.profile-input-container textarea {
  width: 65%;
  border: 1px solid rgb(206, 199, 199);
  border-radius: 4px;
  font-size: 1rem;
  padding: 1%;
  margin: 2% 0;
}
.profile-input-container textarea:hover {
  border-color: #000;
}
.profile-input-container textarea:focus {
  outline: none;
}
.profile-input-container div.MuiOutlinedInput-root {
  width: 65%;
  /* border: 1px solid rgb(206, 199, 199); */
  /* border-radius: 4px; */
  font-size: 1rem;
  padding: 1%;
  margin: 2% 0;
  height: 40px;
}

.profile-info-add-outlet-btn {
  display: flex;
  justify-content: flex-end;
}
.profile-info-add-outlet-btn button {
  width: 22% !important;
  margin: 8px 0 !important;
}
.profile-info-logo-file-upload input[type="file"] {
  display: none;
}
.profile-info-logo-upload-container {
  position: relative;
}
.profile-info-logo-upload {
  position: absolute;
  right: 2%;
  width: 10%;
  font-size: 0.9rem;
}
.profile-info-logo-upload input {
  width: 10%;
}
.profile-info-logo-file-upload {
  position: absolute;
  right: -2px;
  bottom: -12px;
  cursor: pointer;
  background-color: #0011f8;
  color: #fff;
  width: 70px;
  height: 24px;
  text-align: center;
  padding-top: 2%;
}
.profile-info-save-btn {
  margin: 1% 0 !important;
  width: 24% !important;
}
