.new-pyh-cmpt {
  /* padding: 1.5rem; */
  /* background-color: #f8fafc; */
  /* min-height: calc(100vh - 64px); */
}

.new-pyh-cmpt__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  /* max-width: 1920px; */
  margin: 0 auto;
}



@media (min-width: 768px) {
  .new-pyh-cmpt__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .new-pyh-cmpt__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1536px) {
  .new-pyh-cmpt__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.new-pyh-cmpt__card {
  border-radius: 0.75rem;
  border: 1px solid;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(8px);
}

.new-pyh-cmpt__card:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Category-specific styles with improved colors */
.new-pyh-cmpt__card--blue {
  background-color: rgba(239, 246, 255, 0.95);
  border-color: #93c5fd;
}

.new-pyh-cmpt__card--purple {
  background-color: rgba(245, 243, 255, 0.95);
  border-color: #c4b5fd;
}

.new-pyh-cmpt__card--red {
  background-color: rgba(254, 242, 242, 0.95);
  border-color: #fca5a5;
}

.new-pyh-cmpt__card--green {
  background-color: rgba(240, 253, 244, 0.95);
  border-color: #86efac;
}

.new-pyh-cmpt__card--cyan {
  background-color: rgba(236, 254, 255, 0.95);
  border-color: #67e8f9;
}
.new-pyh-cmpt__card--yellow {
  background-color: rgba(255, 251, 235, 0.95); /* Light Yellow */
  border-color: #fef08a; /* Yellow Border */
}

.new-pyh-cmpt__card--orange {
  background-color: rgba(255, 243, 224, 0.95); /* Light Orange */
  border-color: #fdba74; /* Orange Border */
}

.new-pyh-cmpt__card--teal {
  background-color: rgba(230, 245, 243, 0.95); /* Light Teal */
  border-color: #5eead4; /* Teal Border */
}

.new-pyh-cmpt__text--yellow {
  color: #ca8a04; /* Deep Yellow Text */
}

.new-pyh-cmpt__text--orange {
  color: #ea580c; /* Deep Orange Text */
}

.new-pyh-cmpt__text--teal {
  color: #0d9488; /* Deep Teal Text */
}


.new-pyh-cmpt__text--blue { color: #1d4ed8; }
.new-pyh-cmpt__text--purple { color: #6d28d9; }
.new-pyh-cmpt__text--red { color: #dc2626; }
.new-pyh-cmpt__text--green { color: #16a34a; }
.new-pyh-cmpt__text--cyan { color: #0891b2; }
.new-pyh-cmpt__text--yellow { color: #f59e0b; } 
.new-pyh-cmpt__text--orange { color: #f97316; }
.new-pyh-cmpt__text--teal { color: #14b8a6; } 


.new-pyh-cmpt__card-header {
  padding: 1.25rem 1.25rem 0.75rem;
}

.new-pyh-cmpt__header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-pyh-cmpt__category {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.new-pyh-cmpt__badge {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border: 1px solid currentColor;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
}

.new-pyh-cmpt__status {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.new-pyh-cmpt__status-text {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #6b7280;
}

.new-pyh-cmpt__status-text--active {
  color: #16a34a;
}

.new-pyh-cmpt__status-icon {
  vertical-align: middle;
}

/* Enhanced Switch styles */
.new-pyh-cmpt__switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.new-pyh-cmpt__switch--small {
  transform: scale(0.8);
  transform-origin: right center;
}

.new-pyh-cmpt__switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.new-pyh-cmpt__switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e7eb;
  transition: .2s;
  border-radius: 20px;
}

.new-pyh-cmpt__switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .2s;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.new-pyh-cmpt__switch-input:checked + .new-pyh-cmpt__switch-slider {
  background-color: #16a34a;
}

.new-pyh-cmpt__switch-input:checked + .new-pyh-cmpt__switch-slider:before {
  transform: translateX(16px);
}

.new-pyh-cmpt__card-content {
  padding: 0.75rem 1.25rem 1.25rem;
}

.new-pyh-cmpt__name-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  backdrop-filter: blur(4px);
}

.new-pyh-cmpt__name {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
}

.new-pyh-cmpt__separator {
  height: 1px;
  background-color: currentColor;
  opacity: 0.1;
  margin: 1rem 0;
}

.new-pyh-cmpt__fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.new-pyh-cmpt__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.2s ease;
}

.new-pyh-cmpt__field:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.new-pyh-cmpt__field-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4b5563;
  font-weight: 500;
}

.new-pyh-cmpt__field-icon {
  color: #6b7280;
}

.new-pyh-cmpt__field-value {
  font-weight: 600;
  color: #374151;
}

.new-pyh-cmpt__field-value--amount {
  color: #16a34a;
  font-weight: 700;
}