.Total-div{
    display: flex;
    width: 46%;
    justify-content: space-between;
    margin-right: 7%;
}
.Total-div h1{
    margin: 0;
    font-size: small;
}
