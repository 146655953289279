:root {
  --primary-color: #e3e8f5;
}

.profit-and-loss-container {
  padding: 0.5em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.pl-wrapper {
  background-color: white;

  overflow: hidden;
}

.pl-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
}
.pl-date-picker-container {
  display: flex;
  gap: 1em;
}
.pl-body-container {
  padding: 0.5em;
}
.pl-body-header {
  padding-left: 0.4em;
}
.pl-body-header span {
  font-weight: 600;
}
.pl-table-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  height: 70vh;
  overflow-y: auto;
  position: relative;
}

.pl-print-new-th{
  background-color: #072a85;
  color: white;
  font-weight: 600;
}
.pl-print-view-sub-head{
  background-color: #e3e8f5;
  
}
.pl-print-view-Grtt{
  background-color: #072a85;
  color: white;
  
}
.pl-print-view-container{
  display: none;
}

.pl-normal-td {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1.5em;
  background-color: var(--primary-color);
  border-radius: 10px;
  position: sticky;
  top: 0;
  z-index: 10;
}
.pl-normal-td.pl-total-container {
  background-color: #072a85;
  border-radius: 13px;
  z-index: 9;
}
.pl-normal-td.pl-total-container > span {
  color: white;
  font-weight: 600;
}
.pl-normal-td span {
  color: #072a85;
  font-weight: 400;
}

.pl-details-data-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.pl-details-data-container .pl-detail-item :last-child {
  border-bottom: none;
}
.pl-detail-item {
  transition: all 0.5s ease-in-out;
  width: 80%;
  padding: 0.5em 1.5em;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-color);
}
