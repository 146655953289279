.create-journal-container{
    display: flex;
    flex-wrap: wrap;
}
.create-joutnal-input{
    width: 29.3%;
    margin: 1% 2%;
}
.create-journal-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 8% 2%;
}