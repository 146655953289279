.advance-search-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 16px;
  }
  
  .advance-search-relative {
    position: relative;
  }
  
  .advance-search-input {
    width: 100%;
    padding: 10px 16px;
    font-size: 14px;
    color: #4a5568; /* Gray-700 */
    background-color: #fff;
    border: 1px solid #d1d5db; /* Gray-300 */
    border-radius: 4px;
    outline: none;
  }
  
  .advance-search-input:focus {
    border-color: #3b82f6; /* Blue-500 */
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.25); /* Focus ring */
  }
  
  .advance-search-clear-container {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-35%);
    cursor: pointer;
    color: #a0aec0; /* Gray-400 */
  }
  
  .advance-search-icon {
    pointer-events: none;
    color: #a0aec0; /* Gray-400 */
  }
  
  .advance-search-clear {
    cursor: pointer;
  }
  
  .advance-search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    margin-top: 4px;
    padding: 0;
  }
  
  .advance-search-item {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .advance-search-item:hover {
    background-color: #f7fafc; /* Light hover */
  }
  
  .advance-search-item.selected {
    background-color: #ebf8ff; /* Blue-100 */
  }
  
  .advance-search-label {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: bold;
    color: #718096; /* Gray-500 */
    background-color: #f7fafc;
  }
  
  .advance-search-error {
    margin-top: 8px;
    color: #e53e3e; /* Red-600 */
    font-size: 12px;
  }
  
  .highlight {
    background-color: #fefcbf; /* Yellow-200 */
    font-weight: bold;
  }
  