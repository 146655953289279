@page {
    size: A4;
    margin: 11mm 17mm 17mm 17mm;
  }
  
  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }
  
    .content-block,
    p {
      page-break-inside: avoid;
    }
  
    html,
    body {
      width: 210mm;
      height: 297mm;
    }
  }

  .new-print-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; 
    font-size: 10px;
  }
  .new-print-table th, .new-print-table .new-layout-print-footer td{
    background-color: #1d1da7;
    font-weight: 600;
    color: white;
  }
  
  .new-print-table th, .new-print-table td {
    padding: 8px;
    border: 1px solid #ddd;
    word-wrap: break-word; 
    white-space: normal;   
    overflow-wrap: break-word; 
    
  }
  
 
  
