.product-list-main-container{
    background-color: #fff;
    padding: 2%;
}
.product-list-main-container .top-bar{
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}
.product-list-main-container .top-bar .select-type-input{
    width:15%;
}
.product-list-main-container .top-bar .search-container{
    width: 30%;
    height: 36px;
    background-color: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}
.product-list-main-container .top-bar .search-container input[type='text']{
    width: 86%;
    height: 100%;
    background-color: #eee;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    padding-left: 2%;
}
.product-list-main-container .top-bar .search-container input[type='text']:focus{
    outline: none;
}
.product-list-main-container .top-bar .search-container .search-icon-btn{
    border-radius: 4px;
    /* background-color: #002bff; */
    color: #000;
    width: 10%;
    height: 30px;
    position: relative;
    right: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-container .search-icon-btn .search-icon{ 
    /* width: 14px;
    height: 14px; */
    font-size: 16px;
}
.product-list-main-container .product-list-table-container{
    padding: 0;
}



/******* Product Single view Dialog *********/
.product-single-view-parent{
    padding: 4%;
    width: 60vw;
}
.product-single-view-top-bar{
    border-bottom: 1px solid #000;
}
.product-single-view-top-bar h2{
    margin: 4px 0;
}
.product-single-view-parent .product-single-view-content{
    display: flex;
    justify-content: space-between;
}
.product-single-view-content .product-single-left{
    width: 45%;
    margin: 1% 1% 1% 0;
    display: flex;
    flex-direction: column;
}
.product-single-view-content .product-single-right{
    width: 50%;
    background-color: #f2f2f2;
    margin: 1% 0 1% 1%;
    padding: 1%;
    max-height: 73vh;
    overflow-y: scroll;
}
.product-single-view-content .product-single-right::-webkit-scrollbar{
    width: 4px;
    background-color: rgb(198, 225, 255);
}
.product-single-view-content .product-single-right::-webkit-scrollbar-thumb{
    background-color: rgb(65, 65, 65);
}
.product-single-view-content .product-single-right .single-data-row{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    /* align-items: center; */
    margin: 8px 4px;
}
.product-single-view-content .product-single-right .single-data-row p{
    margin: 0;
}
.product-single-view-content .product-single-right .single-data-row p.label{
    font-weight: 500;
    font-size: 1rem;
    width: 40%;
}
.product-single-view-content .product-single-right .single-data-row p.colon{
    font-weight: 600;
    font-size: 1rem;
    width: 1%;
}
.product-single-view-content .product-single-right .single-data-row p.prod-name{
    font-weight: 600;
    width: 55%;
    padding-right: 8px;
}
.product-single-left .barcode-container{
    width: 100%;
}
.product-single-left .barcode-container p{
    font-weight: 600;
}
.product-single-left .barcode-container .barcode-img-container{
    background-color: #f2f2f2;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-single-left .barcode-container .barcode-img-container img{
    width: 85%;
}

.product-single-view-parent .close-btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
}