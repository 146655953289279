.view-order-container{
    padding: 1% 0;
    /* background-color: #fff; */
    margin-bottom:1%;

    max-height: 60vh;
}
.view-order-container h3{
    margin:0 0 2% 2%;
}
.view-order-top-main-container{
    background-color: white;
}
.select-div{
    border: 1px solid rgb(197 198 233);
    background-color: white;
    border-radius: 5px;
}
.view-order-top-container{
    display: flex;
    justify-content: space-between;
    margin: -15px 1px;
    align-items: center;
}
.view-order-top-left-container{
    display: flex;
    width:62%;
    align-items: center;
}
.filter-head{
    padding-bottom: 5px;
    margin-bottom: 45px;
    margin-left: 10px;
    background-color: white;
    padding-inline: 10px;
    font-size: small;
    color: rgb(92, 92, 229);
}
.popup{
    background-color: white;
    overflow: auto;
    height: 25vh;
    margin-top: -20px;
}
.popup::-webkit-scrollbar {
    width: 4px; /* You can change the width as needed */
  }
  
  /* Customize the track (the background of the scrollbar) */
  .popup::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Customize the thumb (the draggable part of the scrollbar) */
  .popup::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
  .first-table{
  overflow: auto;}

  .first-table::-webkit-scrollbar {
    width: 4px; /* You can change the width as needed */
  }
  
  /* Customize the track (the background of the scrollbar) */
  .first-table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Customize the thumb (the draggable part of the scrollbar) */
  .first-table::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
.popup h1{
    font-size: inherit;
    margin-left: 47px;
    /* padding: 6px 0px; */
    padding-top: 11px;
}
.popup table{
    width: 100%;
    border-spacing: 0px;
    padding-inline: 22px;
}
.popup tr{
background-color:rgb(244 233 245) ;
font-size: small;
}
.popup td{
    text-align: center;
}
.search-head{
    position: absolute;
    top: -10px;
    left: 20px;
    margin: 0;
    /* padding-bottom: 5px;
    margin-bottom: 45px;
    margin-left: 10px; */
    background-color: white;
    /* padding-inline: 10px; */
    font-size: small;
    color: rgb(92, 92, 229);
}
.view-order-top-left-container .os-balance-container{
    background-color: red;
    display: flex;
    height:34px;
    width: 28%;
    margin: 2%;
    border-radius: 4px;
    align-items: center;
    padding: 1%;
    justify-content: space-between;
}
.view-order-top-left-container .os-balance-container p{
    font-size: 0.6rem;
    color: #eee;
}
.view-order-top-left-container .os-balance-container h5{
    color: #fff;
    font-size:0.7rem;
}
.view-order-top-right-container .os-balance-container{
    background-color:#e3ddff;;
    color: #1166ff;
    display: flex;
    height:36px;
    width: 41%;
    margin-left: 6px;
    border-radius: 4px;
    align-items: center;
    padding: 1%;
    justify-content: space-between;
}
.view-order-top-right-container .os-balance-container-d{
    background-color: rgb(194 235 208);
    color: #1166ff;
    display: flex;
    height:36px;
    width: 41%;
    margin-left: 6px;
    border-radius: 4px;
    align-items: center;
    padding: 1%;
    justify-content: space-between;
}
.view-order-top-right-container .os-balance-container p{
    font-size: 0.7rem;
    color:#0e26e3;
}
.view-order-top-right-container .os-balance-container h5{
    color: #0e26e3;
    font-size:0.7rem
}
.right-side-search-div{
    display: flex;
    width: 50%;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;
}

.view-order-top-right-container{
    display: flex;
    /* width: 50%; */
    width: 35%;
    justify-content:end;
    align-items: center;
}
.cutting-slip-button{
    width:8%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cutting-slip-print-icon{
    color: #fff !important;
    font-size: 1.3rem;
}
.view-order-search-container{
    align-items: center;
    background-color: #fff;
    /* box-shadow: 0 2px 4px rgb(209, 204, 204); */
    border: 1px solid  rgb(197 198 233);
    height: 36px;
    margin:3% 0%;
    width:50%;
    border-radius: 4px;
     display: flex;
   /* justify-content: space-between; */
    position: relative;
}
.calendar-icon{
 color:#be45f9;
}
.view-order-search-container input{
    border: none;
    height: 31px;
    font-size: 0.8rem;
    padding: 0 4%;
    width: 100%;
}
.view-order-search-container input::placeholder{
    font-size:0.7rem;
    color: rgb(201, 197, 197);
}
.search-icon-order{
    font-size:1rem;
    font-weight: 600;
  
}
.search-icon-button{
    position: absolute !important;
    right: 0;
    height: 36px !important;
    border-radius: 2px 4px 4px 2px;
    margin: 0px;
    background-color: #63ac58;
    color: #fff;
    width: 35%;
    font-size: 0.7rem;
}
.view-order-table-container tbody tr td{
    font-size: 0.7rem !important;
}
.relation-icon{
    font-size: 1.1rem;
    color: #ff8b06;
}
.relation-icon-disabled{
    font-size: 1.1rem;
    color: #ccb7a0;
}
.view-order-status-delivered{
   padding: 6px 8px !important;
   font-size: 0.65rem;
}
.view-order-status-delivery-full{
    font-size: 0.65rem;
    padding: 6px 6px !important;
}
.view-order-status-finish{
    font-size: 0.65rem;
    padding: 6px 9px !important;
    background-color: #b8ebd7 !important;
    color: #28b69e !important;
}
.view-order-status-printing{
    font-size: 0.65rem;
    padding: 6px 4px !important;
    background-color: #cfe4e5 !important;
    color: #2ab5ca !important;
}
.view-order-status-booking{
    font-size: 0.65rem;
    padding: 6px 10px !important;
    background-color: #edd6d1 !important;
    color: #fd4818 !important;
}
.view-order-status-delivery-return{
    padding: 6px 16px !important;
    background-color: #ebd4d9 !important;
    color:  #c10e0c !important;
}
.view-order-status-order-return{
    font-size: 0.65rem;
    padding: 6px 8px !important;
    background-color: #ecacae !important;
    color:  #e4394d !important;
}
.view-order-plus-button{
    background-color: #e8dcff;
    color: #4e38d8;
    border: none;
    height: 28px;
    width:65%;
    font-size: 1.2rem;
    cursor: pointer;
}
.view-order-plus-button:hover{
    background-color: #d7c8f3;
}
.print-cutting-slip-copy:disabled .view-order-visibility-icon{
    color: #b8ebd7;
}
.view-order-visibility-icon{
    font-size: 1.2rem;
}
.icon-btn:disabled i{
    color: #cfe4e5;
}
.view-order-edit-icon{
    font-size: 1rem;
    color: #0a33fe;
}
/* .bi-box-arrow-in-up-left{
    color: #0a33fe;
} */
.view-order-measurement-updation-cell{
    width:10% !important;
}
.view-order-balance-pay-btn{
    background-color: #CDCAFE;
    border: none;
    border-radius: 4px;
    color: #0a33fe;
    padding: 2px 4px;
    align-items: center;
    width: 90%;
    cursor: pointer;
}
.view-order-balance-pay-btn:hover{
background-color: #0a33fe;
color: #fff;
}
.view-order-delivered{
    background-color:#0a33fe !important;
    padding: 4px 12px !important;
}
.view-order-finished{
    background-color: #63ac58 !important;
    padding:4px 14px !important;
}
.view-order-cutting-slip-img{
    width: 70%;
    height: 70%;
}
.view-order-cutting-slip-img-disabled{
    cursor: not-allowed;
    width: 70%;
    height: 70%;
}

/* view order single view style */
.view-order-single-view-container{
    width: 62vw;
    padding:2%;
}
.view-order-single-view-container h3{
   margin: 0;
}
.view-order-input-container{
    display: flex;
    flex-wrap: wrap;
}
.view-order-status-content{
    background-color:#f6f6f6;
    margin: 2% 0;
}
.view-order-status-top-content{
  display: flex;
  justify-content: space-between;
  padding:2%;
}
.view-order-status-top-content h4{
    margin: 0;
}
.view-order-status-content-input-container{
    display: flex;
    padding: 1%;
    flex-wrap: wrap;
}
.view-order-single-view-status{
    width:18% !important;
}
.view-order-single-view-status input,.view-order-single-view-status div.MuiOutlinedInput-root{
    background-color: #fff !important;
     /* caret-color: transparent;    */
     cursor: default;
}
.view-order-single-view-status-select div.MuiOutlinedInput-root{
    background-color: #fff !important;
}
.single-view-deliver{
    padding: 6px 20px !important;
}
.view-order-single-view-btn{
    display: flex;
    justify-content: center;
}

/* print cutting slip */
.view-order-print-cuttingSlip{
    width: 62vw;
    padding: 2%;
}
.view-order-print-cuttingSlip-top-container{
    background-color:#f6f6f6;
    padding: 2% 1% 4% 1%;
}
.view-order-print-cuttingSlip-top-container h3{
    margin: 0;
}
.view-order-print-cuttingSlip-input-container{
    display: flex;
    justify-content:center ;
    align-items: center;
}
.print-cutting-slip-input div.MuiOutlinedInput-root{
    background-color: #fff !important;
}
.print-cuttingSlip-submit-btn{
    margin-top:32px;
}
.cutting-slip-cancel-btn{
    display: flex;
    justify-content: center;
}
.cutting-slip-single-view-print-all-container{
     display: flex;
     justify-content: flex-end;
     margin: 2%;
}
.cutting-slip-single-view-print-all-container label{
    font-size: 0.8rem;
}
.cutting-slip-single-view-print-all-container input{
    width: 16px;
    height: 16px;
}
.print-measurement-container{
    display: flex;
    align-items: center;
    width: 10%;
    justify-content: space-between;
}

.relation-ul{
list-style: none;
padding-left: 4px;
min-width: 100px;
}
.relation-ul li{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.relation-add-btn{
    width: 24px;
    height: 24px;
}
 /* view order admin  */
.view-order-admin-top-right-container{
   width: 75% !important;
}
.view-order-admin-top-right-container .view-order-admin-search-container{
   width: 60% !important;
}
.view-order-admin-top-right-container .os-balance-container-admin{
    background-color: red;
    display: flex;
    height:36px;
    width: 40%;
    margin: 2%;
    border-radius: 4px;
    align-items: center;
    padding: 1%;
    justify-content: space-between;
}
.view-order-admin-top-right-container .os-balance-container-admin p{
    font-size: 0.6rem;
    color: #eee;
}
.view-order-admin-top-right-container .os-balance-container-admin h5{
    color: #fff;
    font-size:0.7rem;
}

/* .vieworderdummy{
    border: 2px solid orange;
} */
.view-order-containerNew{
    background-color: none!important;
}