.head-card-div{
    display: flex;
    width: 30%;
    color: black;
    gap: 15px;
    align-items: center;
}
.head-card-1{
    display: flex;
    width: 42%;
    background-color: white;
    border-radius: 7px;
    padding: 0 16px 0 16px;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    position: relative;
}
.head-card-1::before {
    content: "";
    position: absolute;
    top: 21px;
    left: 1%;
    transform: translateX(-50%);
    width: 5px;
    height: 50%;
    background-color:#35a435;
    border-radius: 0 10px 10px 0px;
}
.head-card-2{
    display: flex;
    width: 42%;
    background-color: white;
    border-radius: 7px;
    padding: 0 16px 0 16px;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    position: relative;
}
.head-card-2::before {
    content: "";
    position: absolute;
    top: 21px;
    left: 1%;
    transform: translateX(-50%);
    width: 5px;
    height: 50%;
    background-color: rgb(230, 32, 32);
    border-radius: 0 10px 10px 0px;
}
/* .arrow{
    transform: rotate(300deg);
    background-color: #a6e9a6;
    border-radius: 12px;
    color: #0a5e0a;
} */