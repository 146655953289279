.staff-loan-report-container{
    width: 100%;
    background-color: #fff;
    padding: 3%;
}
.staff-loan-report-view-dialog{
    width: 60vw;
}


/* staffloanApproval css */
/* staffloanApproval css */
/* staffloanApproval css */

.staff-loan-approval-top-header{
    background-color: #fff;
    margin: 1%;
    padding: 1% 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.staff-loan-approval-top-header-right{
    display: flex;
    width: 60%;
    justify-content: flex-end;
    align-items: center;
}
.staff-loan-approval-top-header-right .global-single-input{
    flex-direction: row;
    width: 75%;
}
.staff-loan-approval-top-header-right .global-single-input input{
   margin: 0 2%;
}
.loan-approval-table-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.status-loan-request-accepted{
    background-color:#b8deb1;
    color: #00bb0f;
    width: fit-content;
    margin: 0 auto;
    border-radius:16px;
    padding:1% 10%;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
}