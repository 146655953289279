.category-list-header{
    display: flex;
    background-color: #fff;
  }
  .category-list-header .arrow-box{
    position: relative;
    /* padding: 4px 0; */
    background-color: #fff;
    min-width: 35px;
    width: auto;
    height: 25px;
  }
  .category-list-header .arrow-para{
    margin-left: 15px;
  }
  .category-list-header .arrow-box .justify-center{
    z-index: 28;
    position: inherit;
    background-color: #fff;
  }
  .category-list-header .arrow-box .justify-center p{
    margin: 0;
    color: #795547;
    font-size: 0.65rem;
    font-weight: 600;
    padding-left: 5px;
    cursor: pointer;
  }
  .category-list-header .arrow-box .justify-center p:hover{

    color: #78000074;
  }
  .category-list-header .arrow-box .cone-shape{
    position: absolute;
    top: 50%;
    right: -10px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%) rotate(45deg);
    background-color: #fff;
    z-index: 8;
    border:1px solid #000;
    border-left: none;
    border-bottom: none;
    content: "";
  }
  
  /* filterbutton css */
  .filter-icon-ctn{
    height: 30px;
    gap: 8px;
    cursor: pointer;
    background-color: #f3f5ff;
    padding: 8px;
    border-radius: 3px;
    color: rgb(92, 92, 229);
    font-weight: 600;
    font-size: 0.9rem;
    position: relative;
  }
  .filter-icon-ctn-drop-down{
    width: 200px;
    position: absolute;
    top: 115%;
    /* left: -75%; */
    right: 10%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    padding: 7px 10px;
    color: #000;
    cursor: pointer;
    background-color: #f3f5ff;
    z-index: 10;
    box-shadow: 2px 3px 4px #4c3d3d;
  }
  .filter-icon-ctn-drop-down::before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #f3f5ff;
    top: -5px;
    content: "";
    height: 0;
    left: 95%;
    margin-left: -24px;
    position: absolute;
    width: 0;
  }
  .filter-span-count{
    position: absolute;
    top: -5px;
    right: -5px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: blue;
    color: #fff;
    z-index: 20;
    font-size: 0.8rem;
  }
 .filter-icon-ctn-drop-down div.filter-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    padding: 7px 0px;
    color: #000;
    direction: ltr;
    cursor: pointer;
  }
  
 .filter-icon-ctn-drop-down div.filter-body p{
    /* font-weight: 600; */
    font-size: 0.9rem;;
  }
 .filter-icon-ctn-drop-down div.filter-body .MuiInputBase-root{
    padding: 0 9px;
  }
 .filter-icon-ctn-drop-down div.filter-body label{
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
 .filter-icon-ctn-drop-down div.filter-body label.MuiInputLabel-shrink{
    top: 0;
    left: 15px;
    color: rgb(92, 92, 229);
    font-size: 0.7rem;
  }
 .filter-icon-ctn-drop-down div.filter-body fieldset{
    border: none;
  }
 .filter-icon-ctn-drop-down  button.btn-secondary{
    margin-top: 25px;
    margin-bottom: 6px;
    color: #fff!important;
    height: 30px;
    border-radius: 4px;
  }
  
 /* timer ctn */
 .timer-ctn{
    width: 25px;
    height: 25px;
    border-radius: 9px;
    background-color: #fff;
    font-size: 0.7rem;
 }



 .bin-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
 background-color: transparent;
  cursor: pointer;
  transition-duration: 0.3s;
  position: relative;
  overflow: hidden;
}
.bin-bottom {
  width: 15px;
  z-index: 2;
}
.bin-top {
  width: 17px;
  transform-origin: right;
  transition-duration: 0.3s;
  z-index: 2;
}
.bin-button:hover .bin-top {
  transform: rotate(45deg);
}

.bin-button:active {
  transform: scale(0.9);
}
.garbage {
  position: absolute;
  width: 14px;
  height: auto;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}
.bin-button:hover .garbage {
  animation: throw 0.4s linear;
}

.add-btn{
  height: 100%;
  background-color: #34a853;
  color: #fff;
  border: none;
  padding: 0 12px;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 4px;

}
@keyframes throw {
  from {
    transform: translate(-400%, -700%);
    opacity: 0;
  }
  to {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}
