.image-upload-component-container {
  position: relative;

  /* border: 1px solid #ccc; */
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.image-preview-container-new {
  /* width: 100%;
  height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.image-preview-container-new img {
  /* width: 100px;
  height: 100px; */
  object-fit: contain;
}

.remove-image-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  display: none !important;
  transition: all 0.3s ease-in-out;
}

.upload-image-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-top: 10px;
}

.upload-image-svg:hover {
  opacity: 0.8;
}

.image-preview-container-new:hover .remove-image-icon {
  display: block !important;
}
