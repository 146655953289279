.parent-login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.child-container {
  /* width: 50%; */
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.right {
  flex: 45;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eee;
}
.left {
  flex: 55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-content-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 60%;
  height: 80%;
  margin-top: 10%;
}
.login-form-container {
  display: flex;
  /* margin-top: 6%; */
  width: 85%;
  margin: 12% 4% 4% 4%;
  max-width: 650px;
}
.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-name-container,
.password-container {
  width: 100%;
  position: relative;
}
.password-container .password-visible-eye-container{
  position: absolute;
  right: 14px;
  bottom: 11px;
}
.user-name-container .username-wrapper {
  height: 48px;
  width: 95%;
  border-radius: 24px;
  margin: 8px auto;
  border: 1px solid rgb(199, 197, 197);
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
}
.user-name-container p {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 4px 12px;
}
.user-name-container .username-wrapper:focus-within {
  outline: none;
  /* border: 1px solid #70d8a1; */
}

.user-name-container .username-wrapper input {
  width: 100%;
  height: 30px;
  padding: 8px 8px 8px 24px;
  border-radius: 22px;
  font-size: 1rem;
  border: none;
  outline: none;
}
.username-wrapper .user-name-tick-container {
  width: 16px;
  height: 16px;
  border: 2px solid #38ab08;
  position: absolute;
  right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.username-wrapper .username-next-arrow-container {
  /* background-color: #000; */
  width: 32px;
  height: 32px;
  padding: 1%;
  position: absolute;
  right: 4px;
  border: 1px solid #000;
  /* border-radius: 4px 22px 22px 4px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.username-wrapper .username-next-arrow-container:hover {
  border: 1px solid #38ab08;
}

.btn-hide {
  display: none;
}
.change-userName-container {
  position: absolute;
  right: 8px;
  bottom: 15px;
}
.change-userName-container button {
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 600;
  color: #000;
  border: none;
  cursor: pointer;
}

.password-container p.user-role,
.mobile-no-container p.user-role {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
  color: #38ab08;
}

.password-container input {
  height: 32px;
  width: 88%;
  border-radius: 24px;
  margin: 8px 0;
  font-size: 1rem;
  padding: 8px 8px 8px 24px;
  border: 1px solid rgb(199, 197, 197);
  position: relative;
  left: 10px;
}
.password-container h3 {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0 0 4px 10px;
}
.password-container input:focus {
  outline: none;
  /* border: 1px solid #70d8a1; */
}
.forgot-password-container {
  align-self: flex-end;
}
.forgot-password-container a {
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  color: #000;
}
.login-btn {
  width: 95%;
  height: 40px;
  font-size: 1rem;
  margin: 12% 0;
  border-radius: 24px;
  border: none;
  color: #fff;
  background-color: #000;
  box-shadow: 0 1px 5px rgb(154, 179, 171);
  cursor: pointer;
}

.login-btn:disabled,
.login-btn:disabled:hover {
  color: rgb(174, 174, 174);
  background-color: #eeeeee;
  /* box-shadow: 0 1px 5px rgb(154, 179, 171); */
  cursor: not-allowed;
}
.login-btn-disabled {
  width: 95%;
  height: 40px;
  font-size: 1rem;
  margin: 12% 0;
  border-radius: 24px;
  border: none;
  color: rgb(174, 174, 174);
  background-color: #eeeeee;
  /* box-shadow: 0 1px 5px rgb(154, 179, 171); */
  cursor: not-allowed;
}
.login-btn:hover {
  background-color: rgb(4, 51, 4);
}

.user-company-details-container {
  width: 35%;
  height: 20%;
  margin: 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.user-company-name p {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
}
/* .user-company-logo{
    width: inherit;
    height: 40px; 
   
    margin-top: 4px;
} */

.user-company-logo img {
  /* width: 85%;
    height: 100px;
    object-fit: fill; */
  width: 100%;
  height: 100%;
}
.logo-dashboard-image {
  position: absolute;
  right: 0;
  bottom: 51%;
  width: 26%;
  height: 8%;
}
.logo-dashboard-image img {
  width: 100%;
  height: 100%;
}
.dashboard-image {
  /* margin: 16% 0 0 24%; */
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70%;
  height: 50%;
  display: flex;
  flex-direction: column;
}
.dashboard-image img {
  width: 100%;
  height: 100%;
}

.username-wrapper {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(199, 197, 197);
  height: 32px;
  /* width: 100%; */
  border-radius: 24px;
  margin: 8px 0;
  font-size: 1rem;
  padding: 4px;
}

.mobile-no-container .username-wrapper input.admin-mobile-input {
  flex: 83% !important;
  border-radius: 0 22px 22px 0 !important;
  padding-left: 2%;
}
.mobile-no-container .username-wrapper .left-input-login-user {
  flex: 17% !important;
  border-radius: 22px 0 0 22px !important;
  height: 47px;
  padding-left: 5%;
  cursor: pointer;
  /* margin-right: 1%; */
}
.mobile-no-container .username-wrapper .left-input-login-user:focus {
  outline: none;
}
.left-input-login-user {
  border: none;
}
.right-input-login-user {
  border: none;
}
.logo-container,.eyet-dashboard-bg-image-container{display: none;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login-content-wrapper-forgot-password{
  align-self: flex-end;
    font-weight: 700;
    cursor: pointer;
}
.login-content-wrapper-forgot-password-register{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 12px;
    font-weight: 700;
    cursor: pointer;
}
.login-content-wrapper-forgot-password p:hover,.login-register-new-link:hover{
  color: #737070;
}
.login-content-wrapper-forgot-password p{
  margin: 0;
}
.login-register-new-link{
  color: #0045da;
  text-decoration: none;
  /* font-weight: 600; */
}
/**** Media Queries ****/

@media only screen and (max-width:450px)
{
  .right{
    display:none
  }
  .login-content-wrapper{
    width: 100%;
    margin-top: 0;
  }
  .login-form-container{
    margin: 10% auto 2%;
  }
.login-text-container .login-text{
  margin-left: 12%;
  font-size: 1.7rem;
}
.login-text-container .login-description
{
  margin-left: 12%;
  font-size: 0.9rem;
  font-weight: 500;
  color: #292929;
}
.logo-container{
  display: block;
  width: 46%;
  margin: 2% auto 3%;
}
.logo-container img.logo{
  width: 100%;
}
.left{
  background-color: #eee;
  background-image: url('../../Assets/SVG/mobileBg.svg');
  background-position: 76% 100%;
  position: relative;
  z-index: 10;
}

.eyet-dashboard-bg-image-container{
  display: block;
  position: absolute;
  bottom: -1%;
  right: -26%;
  width: 86%;
  opacity: 0.2;
  z-index: 0;
}
.eyet-dashboard-bg-image-container img{
  width: 100%;
  z-index: 0;
}
#userNameNextBtn{
  z-index: 2;
}
#loginSubmitBtn{
  z-index: 3;
}

}

