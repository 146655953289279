.dashboard-top-container {
  display: flex;
  width: 100%;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: space-between;
  height: 66px;
}
.dashboard-top-container-left-side {
  width: 32%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-top-container-right-side {
  width: 36%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.role-branch-code-container {
  width: 43%;
  /* margin-right: auto; */
}
.role-branch-code-container .role-branch-child {
  color: #000;
  font-weight: 600;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.role-branch-code-container .role-branch-child span.head {
  margin-right: 8px;
}
.role-branch-code-container .role-branch-child span.head-role {
  margin-right: 24px;
}

/* side menu items style*/
.dashboard-sidemenu-container {
  border-left: 4px solid #0bce8c;
  margin: 2% 0;
}
.productlist-sidemenu-container {
  border-left: 4px solid #870bce;
  margin: 2% 0;
}
.productmaster-sidemenu-container {
  border-left: 4px solid #910083;
  margin: 2% 0;
}
.purchaseManager-sidemenu-container {
  border-left: 4px solid #910000;
  margin: 2% 0;
}
.orders-sidemenu-container {
  border-left: 4px solid #00914d;
  margin: 2% 0;
}
.sales-sidemenu-container {
  border-left: 4px solid #fc6107;
  margin: 2% 0;
}
.customer-sidemenu-container {
  border-left: 4px solid #fc0707;
  margin: 2% 0;
}
.expense-sidemenu-container {
  border-left: 4px solid #e5d709;
  margin: 2% 0;
}
.rewards-sidemenu-container {
  border-left: 4px solid #ce590b;
  margin: 2% 0;
}
.offer-sidemenu-container {
  border-left: 4px solid #ce0b0b;
  margin: 2% 0;
}
.wallet-sidemenu-container {
  border-left: 4px solid #af0bce;
  margin: 2% 0;
}
.report-sidemenu-container {
  border-left: 4px solid #cebb0b;
  margin: 2% 0;
}
.accounts-sidemenu-container {
  border-left: 4px solid #01818f;
  margin: 2% 0;
}
.staff-sidemenu-container {
  border-left: 4px solid #6d1b7b;
  margin: 2% 0;
}
.settings-sidemenu-container {
  border-left: 4px solid #b23c17;
  margin: 2% 0;
}
.qrcode-sidemenu-container {
  border-left: 4px solid #14a37f;
  margin: 2% 0;
}

.devicesettings-sidemenu-container {
  border-left: 4px solid #01818f;
  margin: 2% 0;
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.user-info p {
  font-weight: 500;
}

.product-list-icon {
  width: 25px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #870bce;
}
.focus-none-icon {
  width: 25px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.focus-none {
  border-left: 4px solid #fff;
  margin: 2% 0;
}
.purchase-manage-item {
  margin-left: 8%;
}
.stock-adjustment-item {
  margin-left: 12%;
}
.stock-adjustment-sidemenu ul {
  width: 88%;
}
.transfer-sidemenu ul {
  width: 88%;
}
.transfer-item {
  margin-left: 12%;
}

/* Chat Icon  */
.chat-icon {
  transform: rotateY(180deg);
  color: #00914d;
  font-size: 1.5rem;
}

/* shift style */

.dashboard-shift-status-main-container {
  width: 74%;
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  height: 30px;
  min-width: 200px;
  max-width: 230px;
}
.dashboard-shift-status-main-container .shift-status {
  display: flex;
  width: 58%;
  justify-content: space-between;
}
.dashboard-shift-status-main-container .shift-status p {
  margin: 0;
  color: #000;
  font-size: 0.7rem;
}
.dashboard-shift-status-main-container .shift-status .shift-status-active {
  display: flex;
  align-items: center;
}
.dashboard-shift-status-main-container
  .shift-status
  .shift-status-active
  .shift-status-active-round {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #48aa54;
}
.dashboard-shift-status-main-container .shift-status .shift-status-active p {
  font-size: 0.7rem;
  color: #00914d;
  margin-left: 6px;
  font-weight: 600;
}
.dashboard-shift-status-main-container p {
  color: red;
  font-size: 0.7rem;
  margin: 1% 0;
  font-weight: 600;
}
.dashboard-top-container-left-side .start-button {
  background-color: #01a156;
  border: none;
  color: #fff;
  font-size: 0.8rem;
  width: 20%;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  max-width:90px;
  min-width:60px;
}
.end-button {
  background-color: red !important;
}

.shift-end-button-password-container {
  width: 35vw;
  padding: 1% 8%;
  display: flex;
  flex-direction: column;
}
.shift-end-button-password-container .shit-end-password-input {
  width: 98% !important;
}
.shift-end-button-password-container .shift-end-password-btn {
  align-self: center;
  width: 30%;
  margin: 8% 1% 2% 1%;
}

/* denomination tabel css */

.add-denomination-input-container-table {
  width: 100%;
  margin-top: 5%;
}
.add-denomination-input-container-table table {
  width: 100%;
  border-collapse: collapse;
}
.add-denomination-input-container-table .global-single-input {
  width: 95%;
  margin-left: 0%;
}
.add-denomination-input-container-table th {
  text-align: start;
  padding-bottom: 5%;
}

.add-denomination-input-container-table table td {
  width: 30%;
  margin: 1%;
}
.add-denomination-input-container-table button.shift-single-add-btn {
  background-color: #000;
  height: 40px;
  width: 100%;
  color: #fff;
  margin: 50% 1% 10%;
  font-size: 1.3rem;
  cursor: pointer;
}

.add-denomination-input-container-table table tbody td {
  border: 1px solid #c7c2c2;
  text-align: center;
  padding: 1% 0;
  font-size: 0.9rem;
}

.execute-recurring-post {
  width: 30vw;
  padding: 2%;
}
.execute-recurring-post .close-btn {
  color: red;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.execute-recurring-post .execute-recurring-post-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-block-end: 10%;
}
.execute-recurring-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 2%;
  gap: 1%;
}
.execute-recurring-post-container h3 {
  margin: 3% 0%;
}
.execute-recurring-btn-container button {
  width: 30%;
}
.execute-recurring-btn-container .btn-black {
  background-color: black;
}
.execute-recurring-btn-container .btn-black:hover {
  background-color: rgb(33, 32, 32);
}

/* cash in or out coin */
.cash-in-out-table {
  background-color: #fff;
  max-height: 42vh;
  overflow-y: auto;
}
.cash-in-out-table table {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
}
.cash-in-out-table table thead {
  position: sticky;
  top: -6px;
  background-color: #f7e4ff;
  height: 30px;
  z-index: 2;
}
.cash-in-out-table table thead tr th {
  height: 100%;
  text-align: center;
  font-size: 0.8rem;
}
.cash-in-out-table table tbody {
  min-height: 36px;
}
.cash-in-out-table table tbody tr td {
  min-height: 36px;
  height: 100%;
  text-align: center;
  min-height: 40px;
  height: 30px;
  font-size: 0.8rem;
}

.kot-sidemenu-container {
	border-left: 4px solid #cc009c;
	margin: 2% 0;
}
.kot-token-display-sidemenu-container {
	border-left: 4px solid #293989;
	margin: 2% 0;
}
.customer-display-sidemenu-container {
	border-left: 4px solid #77c1f3;
	margin: 2% 0;
}



.sidemenu-ant-ctn{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  transition: width 0.5s ease;
}


.sidemenu-ant-ctn .ant-menu-root{
  width: 100%;
  transition: width 0.5s ease;
  max-height: 85vh;
  overflow-y: auto;
}
.sidemenu-ant-ctn .ant-menu::-webkit-scrollbar{
  width: 2px;
}
.sidemenu-ant-ctn .ant-menu::-webkit-scrollbar-track{
  background: #f1f1f1;
}
.sidemenu-ant-ctn .ant-menu::-webkit-scrollbar-thumb{
  background-color: #c1c1c1;
}
.ant-menu-submenu-popup{
  z-index: 1260!important;
}

.drawer .MuiDrawer-paper{
  position: inherit!important;
  width:60px!important
}
.menu-expand-btn{
  position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    border-radius: 0 6px 6px 0;
    padding: 0px 3px;
    background: #001529;
    height: 50px;
}

@media(min-width:1300px){
  .dashboard-shift-status-main-container{
    margin-left:100px;
  }
  .role-branch-code-container{
    margin-right: 40px;
  }
}

@media(max-width:1080px){
  .MuiToolbar-gutters{
    padding:0 5px !important
  }
}