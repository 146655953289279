.utility-shift-password-container{
    width: 38vw;
    padding: 6%;
    display: flex;
    flex-direction: column;

}
.utility-shift-password-container h4,.utility-shift-transfer-container h4{
    margin: 0;
}
.utility-shift-password-container .utility-shift-password-input{
    width:98% !important;
    margin: 4% 1%;
}
.utility-shift-password-container .utility-shift-button{
    align-self: center;
    width: 30% !important;
    margin: 8% 1% 1% 1% !important;
}


.utility-shift-transfer-container{
    width: 38vw;
    padding: 6%;
    display: flex;
    flex-direction: column;

}
.utility-shift-transfer-container .utility-shift-transfer-input{
    width:98% !important;
    margin: 4% 1%;
    position: relative;
}
.utility-shift-transfer-input>:nth-child(3){
    color: red;
    align-self: flex-end;
    margin-top: 2%;
    position: absolute;
    top: 85%;
}
.utility-shift-transfer-container .utility-shift-button{
    align-self: center;
    width: 30% !important;
    margin: 8% 1% 1% 1% !important;
}
.utility-shift-transfer-input .transfer-document-button{
    position: absolute;
    top: 38%;
    left: 72%;
    font-size: 0.7rem;
    width: 25%;
    height: 25px;
}
.utility-shift-transfer-input .transfer-to-button{
    position: absolute;
    top: 30%;
    left: 72%;
    color: #fff;
    background-color: #8000ff;
    font-size: 0.7rem;
    width: 25%;
    height: 25px;
}



