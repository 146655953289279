.shift-container{
    background-color: #fff;
    border-radius: 8px;
    padding:5% 0;
    height: 20vh;
    margin: 0 1%;
}
.shift-container div:hover{
    background-color:#eeeff8;
}
.shift-container ul{
    margin: 0 2%;
}
.shift-single-menu{
    padding:1% !important;
    display: flex;
    align-items: center;
    margin: 2% 0 !important;
}
.add-shift-bottom-container{
    margin:2% 1% !important;
}