.add-color-popup-input-container{
    display: flex;
    justify-content: space-between;
    margin:6% 0;
}
.input-container{
    border: none;
    background-color: #eee;
    height: 42px;
    width:42%;
    padding: 2%;
    font-size:1rem;
}
.input-container::placeholder{
    font-size:0.9rem;
}
.color-input-container{
    width: 20%;
    height: 42px
}
.add-color-popup-input-container input[type=color]{
    border: none;
    padding: 0;
    margin: 0;
    /* border-radius:50%; */
    background-color: #fff;
   
}
.add-color-popup-input-container input[type=color]::-webkit-color-swatch{
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.add-color-popup-input-container button{
    background-color: #272a2c;
    height:42px;
    width:12%;
    color: #fff;
    font-size: 1.6rem !important;
    font-weight:500;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}
.add-color-popup-input-container button:hover{
    background-color: #000;
}