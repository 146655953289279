.add-button{
    height: 28px;
    width: 60px;
    border: none;
    color: white;
    background-color: #93169a;
    border-radius: 3px;
}
.reward-box{
    margin: 0px 0px 12px 21px;
    display: flex;
    width: 46%;
    justify-content: space-between;
    padding-inline: 14px;
    border-radius: 8px;
    border: 3px solid rgb(191, 189, 189);
    background-color: #f0f0f0;
}
.cancel-button{
    height: 20px;
    width: 67px;
    font-size: large;
    margin-right: 33px;
    border: none;
    background-color: white;
    color: #f92424
}
.save-button{
    height: 25px;
    width: 79px;
    border-radius: 4px;
    font-size: large;
   
    border: 2px solid blue;
    background-color: #ebecf8;
    color: #010ffa;
}