.sms-settings-container{
    display: flex;
}
.sms-settings-left-container{
    display: flex;
    flex: 45%;
    padding: 4% 0%;
    background-color: #fff;
    margin: 0 1%;
    flex-direction: column;
    height: 85vh;
    border-radius: 4px;
}
.sms-settings-left-container div:hover{
    background-color:#eeeff8;
}
.sms-settings-right-container {
    flex: 55%;
    padding: 3% 3%;
    background-color: #fff;
    margin: 0 1%;
    height: 80vh;
    flex-direction: column;
    border-radius: 4px;
  }

.sms-settings-container-left-list{
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 6%;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}
.sms-settings-accordian-section{
    display: flex;
}
.sms-settings-accordian-section label{
    padding: 0 10px;
    font-size: 0.8rem;
    line-height: 1.5rem;
    margin: 5% 0;
}
.sms-settings-right-container-header-top{
    margin-top: 2%;
}
.sms-settings-right-container-header-top p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.sms-settings-right-container-header-top input{
    width: 98%;
    height: 50px;
    margin: 2% 0;
    border: 0.3px solid rgb(0 0 0 / 26%);
    font-size: 0.9rem;
    padding: 0 16px;
}
.sms-settings-right-container-header-top input::placeholder {
    opacity: 0.5;
  }
.sms-settings-right-container-header-top-button{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 2%;
}
.sms-settings-right-container-header-top-button button{
    width: 30%;
}

.approved{
    color: #00bf78;
}
.sms-settings-right-container-header-bottom-thead{
    border-bottom: 2px solid rgb(0 0 0 / 10%);
}
.sms-settings-right-container-header-bottom table tbody tr td{
    background-color: #fff;
    font-weight: 600;
}
.sms-settings-right-container-header-checked input{
    width: 98%;
    height: 60px;
    margin: 2% 0;
    background-color: #f6f6f6;
    border: none;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0 16px;
}

.sms-settings-right-container-top-auto-complete{
    width: 100%;
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sms-settings-right-container-top-auto-complete div.MuiOutlinedInput-root{
    width: 98%;
    border: none;
    background-color: #f6f6f6;
    height: 50px;
    border-radius: 0px;
}
.template-msg-radio{
    display: flex;
    align-items: center;
    width: 48% !important;
}
.template-msg-radio input{
    width: 13%;
}
.sms-settings-right-container-header-top textarea{
    margin-top: 3%;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    width: 100%;
    height: 20vh;
    resize: none;
    outline: none;
}
.sms-settings-right-container-header-
.sms-settings-right-container-header-top textarea::placeholder{
    font-size: 1rem;
    font-weight: 600;
    padding: 0px 25px;
    color:#9e9e9e;
  }