/* page layout styles */
.page-layout-wrapper {
  padding: 0.5em;
}
.page-layout-container {
  background-color: white;
  height: 91vh;
  padding: 1.5em;
  overflow-y: auto;
  position: relative;
  transition: all 0.3s ease;
}

/* page header component  */
.new-section-header {
  /* Add styles for the overall header */
  /* padding: 1em; */
  /* margin-bottom: 1em; */
  position: sticky;
  top: -25px;
  background-color: white;
  z-index: 10;
  padding: 0 7px;
  padding-bottom: 1em;
}

.new-section-header__content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
}

.new-section-header__left {
  /* Styles for the left side (title) */
}

.new-section-header__title {
  /* Styles for the "Pay Slip" title */
}

.new-section-header__right {
  display: flex;
  align-items: center;
}

.new-section-header__filter,
.new-section-header__search,
.new-section-header__create-button {
  /* margin-left: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-section-header__create-button {
  all: unset;
  padding: 0.6em;
  background-color: #8400b2;
  border-radius: 5px;
  color: white;
  min-width: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.new-section-header__create-button:hover {
  background-color: #a200db; /* Slightly lighter shade */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.new-section-header__select,
.new-section-header__input {
  /* Styles for select and input fields */
}

.new-section-header__search-button,
.new-section-header__create-button {
  /* Styles for buttons */
}

.new-section-header__pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/* card styles */

.payroll-unq-card {
  background: white;
  /* border-radius: 8px; */
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #6c5ce7;
  margin-bottom: 16px;
  max-width: 30em;
}

.payroll-unq-card__header {
  margin-bottom: 16px;
}

.payroll-unq-card__title {
  font-size: 16px;
  font-weight: 600;
  color: #2d3436;
  margin: 0;
}

.payroll-unq-card__content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.payroll-unq-card__time-group {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.payroll-unq-card__check {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.payroll-unq-card__label {
  font-size: 12px;
  color: #636e72;
}

.payroll-unq-card__date,
.payroll-unq-card__time {
  font-size: 14px;
  color: #2d3436;
  font-weight: 500;
}

.payroll-unq-card__duration {
  display: flex;
  flex-direction: column;
  gap: 4px;
}