.company-settings-tab-container{
  background-color: #fff;
  /* margin: 0 1%; */
  border-radius: 8px;
  width: 100%;
  height: 10%;
  padding: 1%;
}
.company-settings-tab-menu-container{
    border-radius: 4px;
    width: 100%;
    height: 90%;
    border-bottom: 1px solid rgb(138, 138, 138);
    margin-bottom: 4px;  
}
.company-settings-tab-menus-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 2%;
}
.tab-menu-button{
    padding: 5px 16px;
    cursor: pointer;
}
.active{
    color: #2e06ff;
    border-bottom: 4px solid #2e06ff;
    border-radius: 4px;
}
.inactive{
    color:#818181
}