.stock-summary-input{
    width: 23% !important;
    
}
/* print view css */
.print-view-top-container{
    display: flex;
    /* justify-content: space-between; */
    padding:2% 6%;
}
.print-view-top-left-container{
    width: 20%;
}
.print-view-top-left-container p{
     margin: 3%;
     font-size: 1rem;
     font-weight: 600;

}
.print-view-top-middle-container {
    width: 60%;
}
.print-view-top-middle-container h3{
    text-align: center;
    margin: 1%;
} 
.print-view-top-middle-container p{
    text-align: center;
    margin: 1%;
    font-size: 1rem;
    font-weight: 600;
} 
.print-view-top-right-container{
    width: 20%;
}
.print-view-top-right-container p{
  margin: 3%;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
}
.print-view-table-container{
    /* margin:2% 6%; */
    padding:1% 6%;
}
.print-view-table-container table{
    width: 100%;
    border-collapse: collapse;
}
.print-view-table-container thead tr th{
    font-size: 0.8rem;
    border-top: 1px solid #000;
    border-bottom:1px solid #000 ;
    text-align: center;
}
.print-view-table-container tbody tr{
    /* border-bottom: 4px solid #fff; */
    height: 30px;
}
.print-view-table-container tbody tr td{
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
}


.print-table-report-expend-view{
    display: flex;
    justify-content: center;
    align-items: center;
 
}
.print-report-hr{
    border: none;
    border-bottom: 1px solid #000;
    margin: 0 6% 2% 6%;
    
}
.print-report-input-container{
   display: flex;
}
.print-report-input-container p{
    font-size:1rem;
    font-weight: 500;
}
.print-report-input-container input{
    background-color: transparent;
    border:1px solid rgb(184, 181, 181);
    width: 72%;
}