.apply-input-radio-container {
    margin-left: 0.5em;
}
.apply-input-radio-container .heading{
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;

}
.apply-input-radio-container ul{
    list-style: none;
    padding: 0px;
    display: flex;
    margin: 0;
    align-items: end;

}
.apply-input-radio-container li{
    margin-right: 20px;
    margin-bottom: 10px;

}
.apply-input-radio-container label {
    margin-left: 5px;
  }
  .apply-input-radio-container input[type="radio"]:checked {
  accent-color: black;
  }

  .apply-input-radio-container .category-input{
    width: auto !important;
  }

  .buy-n-get-n-container{
    display: flex;
  }
  .apply-input-radio-container input,
  .apply-input-radio-container label {
      cursor: pointer;
  }
  .price-list-transaction input {
    cursor: pointer;
  }