.pos-receipt-template-container {
  background-color: #ffff;
  padding: 1em;
}
.pos-receipt-template-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.template-header-company-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.template-header-company-info .template-restaurant-name,
.template-header-company-info .template-transaction-number {
  font-weight: 600;
  font-size: 1em;
  text-transform: uppercase;
}
.template-header-company-info span {
  font-size: 0.875em;
}

.template-invoice-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.template-invoice-details span {
  text-transform: uppercase;
  font-size: 0.8125em;
  font-weight: 600;
}

.pos-receipt-template-container .temp-bol-txt {
  font-size: 0.9375em;
  font-weight: 600;
}
.pos-receipt-template-container .temp-text-end {
  text-align: end;
}
.template-dotted-line {
  border: 1px dotted #000000;
  width: 100%;
}
.template-dashed-line {
  border: 1px solid #000000;
  width: 100%;
}

.temp-multi-line {
  display: flex;
  flex-direction: column;
}

/* footer  */
.pos-receipt-template-footer {
  font-size: 0.8125em;
}
.fr-greeting {
  margin: 0.5em 0;
}
.footer-summary {
  max-width: 20em;
  text-align: center;
}
.template-footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* table - body  */
.template-table-container table {
  margin: 1em 0;
  width: 100%;
}

.template-table-container table td {
  padding: 0.5em;
}
.template-table-container .table-item {
  display: flex;
  flex-direction: column;
}
.template-table-container .table-item-center {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.temp-two-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.template-table-container table th {
  border-bottom: 2px dotted #8080809c;
  height: 2em;
  padding: 0.5em;
}
.temp-table-head {
  display: flex;
  flex-direction: column;
}
.temp-net-amount {
  margin-bottom: 0.5em;
}
.temp-last-raw td > div {
  display: flex;
  flex-direction: column;
}
.temp-last-raw td {
  border-bottom: 1px dotted #635e5e4f;
}

/* company logo */
.template-header-company-logo {
  width: 12.5em;
  height: 6.25em;
  overflow: hidden;
}
.template-header-company-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* payment details  */
.template-payment-details {
  display: flex;
  justify-content: space-between;
}

/* token */
.temp-token-name {
  font-size: 1.5em;
  font-weight: 600;
}
.template-token-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-token-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.template-token-container .temp-token-no {
  font-weight: 600;
  font-size: 6em;
}
.template-table-container .sub-data-info {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 600;
}
.template-table-container .sub-data-info-font{
  font-size: 1.2em;
  font-weight: 600;
}
.template-table-container .sub-data-info span {
  display: block;

}
.template-table-container .sub-data-info-start {
  text-align: start !important;
  
}
.template-table-container .sub-data-info-end {
  text-align: end !important;
}
