.product-master-container{
    display: flex;
}
.product-master-left-main-container{
width: 50%;
margin: 2% 0;
}
.product-master-right-main-container{
width: 50%;
margin: 2% 0;
}
.product-master-left-container{
  background-color: #fff;
  padding:1% 2%;
}
.product-master-right-container{
    background-color: #fff;
    padding:11% 2%;
    display: flex;
    flex-wrap: wrap;
}
.product-master-left-top-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% 0;
}
.product-master-left-top-container p{
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
}
.product-master-left-second-container{
    background-color: #fff;
    padding: 5% 2% 8% 2%;
    margin-top: 6%;
    /* margin: 4% 0; */
}
.product-master-input-container{
    margin:4% 0;
    display: flex;
    flex-wrap: wrap;
}
.product-master-input{
    width:48% !important;
}
.product-master-submit-btn{
  display: flex;
  justify-content: flex-end;
}
.product-master-description-input{
    width:98% !important;
}
.product-master-additional-info{
    width: 75% !important
}
.product-master-additional-info-sub{
    display: flex;
    justify-content: space-between;
}
.product-master-additional-info-sub input{
    width:88%;
}
.product-master-additional-info-sub button{
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    width:34px;
    height: 38px;
    font-size: 1.3rem;
    cursor: pointer;
}
.product-master-additional-info-sub button:disabled{
    background-color: #eee;
}
.image-product-master{
    display: flex;
    justify-content: space-between;
}
.product-master-image{
    width:98% !important;
}
.product-master-category-filter-input{
    display: flex;
    flex-wrap: wrap;
}
.product-info-head{
    margin:0 0 3% 0;
    font-size: 1rem;
    font-weight: 500;
}

.file-picker-group .single-img-update-btn{
    width: 100%;
    cursor: pointer;
    margin-top: 8px;
    background-color: rgb(53, 88, 164);
    border: none;
    color: #fff;
    border-radius: 4px;
}
.file-picker-group .single-img-update-btn:hover{
    background-color: rgb(24, 62, 146);
}
.product-master-product-type{
    display: flex;
    justify-content: space-between;
}
.product-master-product-type-radio-grp{

}
.product-master-product-type-radio-grp-single{
    display: flex;
    align-items: center;
    height: 27%;
}
.product-master-product-type-radio-grp-single input[type="radio"]{
    width: 13px;
    accent-color: #000;
    cursor: pointer;
}
.product-master-product-type-radio-grp-single label{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 4% 0% 0 3%;
    cursor: pointer;
}