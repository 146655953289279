.dash-card-ctr {
  background-color: white;
  /* padding: 1em; */
  /* width: 13em; */
  width: 100%;
  border-radius: 10px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 1em;
}
.dash-card-ctr > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.dash-card-ctr.large-dash-cntr > div {
min-height: 8.6em;
}
.multiple-cntr {
  background-color: #fafafa;
  align-self: center;
  width: 100%;
  text-align: center;
  padding: 1.5em 0;
}
.multiple-cntr.dash-cntr-same{
  text-align: start;
  padding-left: 2em;
  background-color: #ffff;


}
.dash-card-ctr > :nth-child(2) {
  text-align: center;
  font-size: 12px;
  padding: 1.5em 0;
}
.dash-card-ctr > :nth-child(2) > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0.5em;
}

.dash-name {
  font-weight: 600;
  line-height: 2.5em;
  text-transform: capitalize;
}
.dash-name.dash-text-transform{
text-transform: uppercase;
}
.dash-amt {
  font-weight: 600;
}
.sub-amt {
  font-weight: 600;
}
.dash-profit {
  color: green;
}
.dash-loss {
  color: red;
}
