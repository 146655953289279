.staff-leave-application-top{
    margin: 1%;
    margin-bottom: 0;
    background: #fff;
    display: flex;
}
.staff-leave-application-top-left{
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
}
.staff-leave-application-top-section{
    display: flex;
    width: 65%;
    justify-content: space-between;
    align-items: center;
}
.staff-leave-application-top-section .global-single-input{
    width: 35%;
}
.staff-leave-application-top-right{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.staff-leave-application-top-right .global-single-input{
    width: 60%;
}
.staff-leave-application-top-right .global-single-input>:nth-child(1){
    background-color: #f36f16;
    color: #fff;
}
.btn-staff-table{
    width: 70%;
    font-size: 0.8rem;
}
.leave-application-view-container{
    background-color: #fff;
    width: 40vw;
    padding: 3% 6%;
    display: flex;
    flex-direction: column;
}
.leave-application-view-container-top{
    padding: 2% 0;
    border-bottom: 2px solid rgba(44, 44, 44, 0.8);
}
.leave-application-view-container-body{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(65, 65, 65, 0.8);
    padding: 2%;
    margin: 3% 0;
}
.leave-application-view-container-button{
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    width: 45%;
    padding: 2% 0;
}
.leave-application-view-container-button .btn{
    width: 45%;
}
.leave-application-view-container-button .btn-green{
    background-color: #05a84c;
}
.leave-application-view-container-button .btn-green:hover{
    background-color: #007e37;
}
/* .staff-table-check-box{
    display: flex;
    align-self: center;
} */
.staff-table-check-box-tick{
    border: 1px solid #05a84c;
    cursor: pointer;
    height: 30px;
    width: 30px;
    color: #05a84c;
    font-size: 1rem;
    margin: 0 1%;
}
.staff-table-check-box-wrong{
    border: 1px solid red;
    cursor: pointer;
    height: 30px;
    width: 30px;
    margin: 0 1%;
    color: red;
}
.staff-table-check-box-tick:hover,.staff-table-check-box-wrong:hover{
    background-color: #fff;
}


/* StaffHoliday CSS */
/* StaffHoliday CSS */
/* StaffHoliday CSS */
/* StaffHoliday CSS */

.staff-holiday-container-top{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.staff-holiday-container-top .btn{
    background-color: #3355ff;
}
.staff-holiday-container-top .btn:hover{
    background-color: #002aff;
}


.staff-holiday-container{
    padding: 3%;
}
.staff-holiday-edit-container{
    width: 30vw;
    background-color: #fff;
    padding: 2% 5%;
    display: flex;
    flex-direction: column;
}
.staff-holiday-edit-container-top{
    padding: 2% 0;
    border-bottom: 1px solid rgba(166, 166, 166, 0.8);
}
.staff-holiday-edit-container-body{
    display: flex;
    flex-direction: column;
    padding: 2% 0;
}
.staff-holiday-edit-container-body .global-single-input{
    width: 100%;
}
.staff-holiday-edit-container-button{
    align-self: center;
    display: flex;
    width: 60%;
    padding: 3% 0;
}
.staff-holiday-edit-container-button .btn{
    width: 50%;
}



/* staffAddLeaveType css */
/* staffAddLeaveType css */
/* staffAddLeaveType css */
/* staffAddLeaveType css */


.staff-add-leave-type-container-top{
    width: 98%;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin: 1%;
    padding: 2%;
}
.staff-add-leave-type-container-top .btn{
    background-color: #d5840a;
}
.staff-add-leave-type-container-top .btn:hover{
    background-color: #a76400;
}
.validating-input-filed{
    position: relative;
}