.held-order-grid-ctn{
    display: flex;
    flex-wrap: wrap;
    height: 78vh;
    overflow-y: auto;
    padding: 1%;
    gap: 15px 2%;
    align-content: flex-start;
    justify-content: center;
}
.order-card{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: calc(32%);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    border-radius:12px;
    cursor: pointer;
    max-width: 330px;
}
.order-card p{
    margin: 0;
    font-weight: 600;
}
.order-card .order-card-head{
    padding: 6px 16px;
    border-radius:12px 12px 0 0;

}
.order-card .middle-ctn{
    padding:16px;
    padding-right: 0;
    display: flex;
    flex-direction: row;
}
.order-card .middle-ctn-left{
    flex: 1;
    padding-right: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.order-card .middle-ctn-left .customer-tag{
    width: fit-content;
    padding: 4px 10px;
    font-weight: 600;
    border-radius: 6px;
}
.order-card .middle-ctn-left .justify-space-between{
    justify-content: start;
    gap: 20%;
}
.order-card .middle-ctn-right{
    margin-left: auto;
    display: flex;
    gap: 5px;
}
.order-card .middle-ctn-right .hour-label{
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.order-card .middle-ctn-right .hour-value{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.order-card .middle-ctn-right .hour-value p{
    background-color: green;
    border-radius: 6px 0 0 6px;
    padding: 2px 5px;
    font-size: 0.8rem;
    color: #fff;
}

.held-order-grid-ctn .order-card-footer{
    padding:0 16px;
    padding-bottom:16px;
}
.held-order-grid-ctn .order-card-footer div{
    width: fit-content;
    padding: 4px 10px;
    font-weight: 600;
    border-radius: 6px;
    color:#fff;
    font-size: 0.9rem;
}


.held-order-grid-ctn::-webkit-scrollbar{
    width: 6px; 
  }
  
  .held-order-grid-ctn::-webkit-scrollbar-track {
  background-color: #ffffff; 

  }
    
  .held-order-grid-ctn::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;

  }


  
.blinking-red span{
    animation: blinking-animation 0.5s infinite alternate;
}
  .blinking-yellow span{
    animation: blinking-animation-yellow 0.7s infinite alternate;
}
.blinking-yellow-order {
    animation: blinking-animation-yellow-order 1s infinite alternate;
}

.blinking-red-order {
    animation: blinking-animation-red-order 1.1s infinite alternate;
}

@keyframes blinking-animation-yellow-order {
    0% {
        box-shadow: 0 0 20px rgba(209, 209, 23, 0.5); /* Yellow shadow */
    }
    100% {
        box-shadow: none;
    }
}

@keyframes blinking-animation-red-order {
    0% {
        box-shadow: 0 0 20px rgba(255, 0, 0, 0.5); /* Red shadow */
    }
    100% {
        box-shadow: none;
    }
}
@keyframes blinking-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.1;
    }
}
@keyframes blinking-animation-yellow {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}