/* AdvancedFilter.css */

.adv-flt-container {
    position: relative;
    display: inline-block;
  }
  
  .adv-flt-trigger {
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 9px;
    cursor: pointer;
  }
  
  .adv-flt-icon {
    width: 20px;
    height: 20px;
  }
  
  .adv-flt-popover {
    position: absolute;
    top: 108%;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    width: 370px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .adv-flt-back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 0;
    color: #007bff;
  }
  
  .adv-flt-filter-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .adv-flt-filter-option {
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .adv-flt-filter-option:hover {
    background-color: #f0f0f0;
  }
  
  .adv-flt-filter-option .adv-flt-icon {
    margin-right: 12px;
  }
  
  .adv-flt-preview {
    margin-left: auto;
    font-size: 0.8em;
    color: #666;
    max-width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .adv-flt-filter-content {
    margin-bottom: 16px;
  }
  
  .adv-flt-filter-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .adv-flt-date-range {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .adv-flt-date-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .adv-flt-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .adv-flt-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .adv-flt-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .adv-flt-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .adv-flt-button-clear {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .adv-flt-button-apply {
    background-color: #007bff;
    color: white;
  }
  
  .adv-flt-button:hover {
    opacity: 0.9;
  }
  