.daily-report-top-container{
    background-color: #fff;
    padding: 3%;
    position: relative;
}
.daily-report-top-container h3{
    margin: 0;
}
.daily-report-top-input-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2% 0 0 0;
    position: relative;
}
.report-submit-btn{
    margin: 2% 0 0 4%;
}
.report-checkbox-container{
    display: flex;
    justify-content: space-between;
    width: 33%;
    margin: 0 14% 0 auto;
  
}

.report-single-checkbox-container{
    display: flex;
    align-items: center;
   
}
.report-single-checkbox-container label{
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
    margin-left: 2px;
   
}
.report-single-checkbox-container input{
    width: 12px;
    height: 12px;
    accent-color: #000;
    cursor: pointer;
}
.daily-report-bottom-container{
    background-color: #fff;
    padding: 3%;
    margin: 1% 0;
}
.daily-report-bottom-date-location-container{
    display: flex;
    justify-content:space-between;
    margin: 0 4%;
}
.daily-report-bottom-date-location-left-container {
    width: 50%;
}
.daily-report-bottom-date-location-left-single-container{
    display: flex;
    align-items: center;
}
.daily-report-bottom-date-location-container p{
    width: 65%;
    margin:0;
}
.daily-report-bottom-date-location-container h4{
    margin: 0;  
}
.daily-report-bottom-date-location-right-container{
    width: 23%;
}
.daily-report-bottom-date-location-right-container p{
    width: 61%;
    margin: 2% 0; 
}
.report-print-download-icon-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 8% 0 0 0
}

/* details table */
.report-details-table-main tbody tr td{
  padding: 2% 0;
}
.report-details-table td{
  border-bottom: none !important;
}
.report-details-table{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.report-detail-table-main-cell td{
    border-bottom: none !important;
   
}
.report-detail-table-main-cell{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* amount container left */
.daily-report-amount-container{
    display: flex;
}
.daily-report-amount-left-container{
    width: 45%;
}
.daily-report-amount-right-container{
  width: 55%;
}
.daily-report-amount-left-first-container{
    border: 1px solid rgb(165, 164, 164);
    border-radius: 4px;
    width: 92%;
    margin: 1% 4%;
    padding:1% 6%;
}
.daily-report-amount-left-first-single-container{
   display: flex;   
   align-items: center;
}
.daily-report-amount-left-first-single-container p{
    margin: 0;
    width: 58%;
    font-size: 0.9rem;
}
.daily-report-amount-left-first-single-container span{
  font-weight: 600;
  font-size: 1.1rem
}
.daily-report-amount-left-first-single-container h4{
    margin: 0;
    width: 28%;
    text-align: right;
}
.daily-report-amount-left-first-single-container h3{
    width: 58%;
}

/* amount container right */
.daily-report-amount-right-first-container{
    background-color:  #f6f6f6;
    padding: 1% 4%;
    margin: 1% 4%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.daily-report-amount-right-first-single-container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    /* margin: 2% 0; */
    
}
.daily-report-amount-right-first-single-container p{
    margin: 0;
    width: 64%;
    text-align: right;
    font-size: 1rem;
    font-weight: 600;
}
.daily-report-amount-right-first-single-container span{
    font-weight: 600;
    font-size: 1.1rem;
    width: 10%;
    text-align: center;
}
.daily-report-amount-right-first-single-container h4{
    margin:0;
    width: 25%;
    text-align: right;
    font-size: 1.1rem;
    font-weight: 600;
}

.daily-report-grand-total-container{
    background-color: #f6f6f6 ;
    margin: 1% 2%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1% 2%;
}
.daily-report-grand-total-container h3{
    width: 13%;
    text-align: right;
}
.daily-report-grand-total-container span{
    width: 5%;
    text-align: center;
    font-weight: 600;
    font-size: 1.1rem;
}

/* expense table */
.daily-report-expense-table-container{
    margin:0 1%;
}
.daily-report-expense-table-container h4{
    margin: 2% 1%;
}
.daily-report-expense-table{
    max-height: 50vh;
    overflow-y: scroll;
    padding: 1%;
}
.daily-report-expense-table table{
    width: 100%;
    background-color: #eee;
    border-collapse: collapse;
}
.daily-report-expense-table table thead{
    position: sticky;
    top: -6;
    height: 40px;
}
.daily-report-expense-table table thead tr th{
    height: 100%;
    text-align: center;
    font-size: 0.8rem;
}
/* .daily-report-expense-table table tbody{
    height: 40px;
} */
.daily-report-expense-table table tbody tr td{
    height: 50px;
    text-align: center;
    font-size: 0.8rem;
    border-bottom: 1px solid rgb(190, 190, 190);
}
.table-cell-border-none td{
    border-bottom: none !important;
}
.daily-report-expense-table::-webkit-scrollbar{
    width: 4px;
    background-color:#d3e3ec;
}
.daily-report-expense-table::-webkit-scrollbar-thumb{
    background-color:#a7cde4;
}


/* daily report date filter */
 .daily-report-date-filter-container{
    margin: 1% 2%;
}
.daily-report-date-filter-top-container{
    display: flex;
    justify-content: space-between;
}
.daily-report-date-filter-top-container p{
    margin: 0;
}
.daily-report-date-filter-top-container .date-filter-icon{
    width: 214px !important;
    margin: 0 !important;
} 
.daily-report-date-filter-middle-container{
    background-color: #eee;
    padding: 1%;
    margin: 1% 0;
    display: flex;
    justify-content: space-between;
} 

/* daily report date filter print */
.daily-report-date-filter-print-container{
    margin: 1% 8%;
}
.daily-report-date-filter-print-container p{
    margin: 1%;
}
.daily-report-date-filter-top-print-container{
    display: flex;
    justify-content: space-between;
}

/* daily report cash in or out*/
.daily-report-cash-in-or-out{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.daily-report-cash-in-or-out p{
    font-size: 0.9rem;
     margin: 0;
     font-weight: 600;
}
.daily-report-cash-in-or-out table{
    width: 100%;
}
.daily-report-cash-in-or-out table thead{
    height: 23px;
   
}
.daily-report-cash-in-or-out table thead tr th{
   font-size: 0.8rem;
   text-align: left;
   border-bottom: 1px solid #000;
}
.daily-report-cash-in-or-out table tbody tr td{
    font-size: 0.8rem;
    text-align: left;
 }

 /* daily report filter */
 .daily-repirt-filter-container{
    border: 1px solid rgb(236, 153, 236);
    height: 26px;
    width: 42%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    cursor: pointer;
    margin: 0 0 0 8px;
 }
 .daily-repirt-filter-container .aarow-down-filled{
    color: #c5c1c1;
    font-size: 0.8rem;
 }
 .daily-repirt-filter-container .filter-icon{
    color: rgb(238, 63, 238);
 }
 .daily-repirt-filter-content{
    width: 13%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: absolute;
    top: 85%;
    right: 16%;
    background-color: #fff;
    padding: 1%;
    z-index: 3;
}
.daily-repirt-filter-content-checkbox{
    display: flex;
    margin: 8px 0;
}
.daily-repirt-filter-content-checkbox input[type="checkbox"]{
    width:15px;
    height:15px;
    margin-right: 8px;
    cursor: pointer;
    accent-color: #000;
}
.daily-repirt-filter-content-checkbox label{
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}
 

