.aggregator-main-ctn .special-items-table table th{
    font-size: 1rem;
    padding: 8px;
}
.aggregator-main-ctn .special-items-table table tr td{
    font-size: 0.9rem;
    padding: 10px 0;
    cursor: pointer;
}
.aggregator-main-ctn .special-items-table table tr td:hover{
   border: none;
}


.special-items-table table td input[type="radio"] {
    width: 25px;
    height: 20px !important; 
    margin-right: 5px; 
    cursor: pointer;
  }
  .special-items-table table td input[type="radio"]:checked {
    color: black; 
    accent-color: black;
  }

  .aggregator-input-ctn{
    gap: 1%;
    padding: 5px 12px;
    padding-bottom: 20px;
  }
  .aggregator-input-ctn .new-global-single-input{
    width: 32%;
    margin: 8px 0;
  }