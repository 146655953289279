.size-chart-container {
  width: 62vw;
  height: auto;
  padding: 4%;
}
.size-chart-top-container {
  display: flex;
  justify-content: space-between;
}
.size-chart-top-container p {
  margin: 1%;
  font-size: 1.3rem;
  font-weight: 600;
}
.size-chart-top-container-close-icon {
  border: 1px solid red;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin: 1%;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: red;
}

/* .size-chart-second-container{
    
} */
.size-chart-second-input-container {
  display: flex;
  align-items: center;
  margin: 2% 0;
}
.size-chart-add-button {
  display: flex;
  position: relative;
  top: 12px;
}
.size-chart-second-input-container input:disabled {
  background-color: #e6e6e6;
  color: #000;
}
.size-chart-add-button button {
  background-color: #d5e6f8;
  color: #000;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
}
.size-chart-add-button button:hover {
  background-color: #b0d0f3;
}
.size-chart-divider {
  border: none;
  border-top: 1.5px dotted rgb(134, 133, 133);
  margin: 4% 0;
}
.size-chart-add-delete-button {
  display: flex;
}
.size-chart-add-delete-button button {
  background-color: #f8d5d5;
  border: none;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  cursor: pointer;
}
.size-chart-add-delete-button button:hover {
  background-color: #f7b9b9;
}
.third-container {
  max-height: 45vh;
  overflow-y: scroll;
  padding-right: 8px;
}
.third-container::-webkit-scrollbar {
  width: 4px;
  background-color: #e6e6e6;
}
.third-container::-webkit-scrollbar-thumb {
  background-color: #888;
}
.size-chart-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16%;
  margin: 8% 0;
}
.size-chart-size-input {
  width: 15% !important;
}

.selectable-size-chart-parent {
  padding: 4%;
  width: 60vw;
}
.select-size {
  width: 40px;
  height: 40px;
  accent-color: #000;
  cursor: pointer;
}
