.roles-and-permission-container {
  display: flex;
}
.roles-and-permission-left-container {
  display: flex;
  flex: 40%;
  padding: 4% 2%;
  background-color: #fff;
  margin: 0 1%;
  flex-direction: column;
  height: 85vh;
  border-radius: 4px;
}
.roles-and-permission-right-container {
  flex: 60%;
  padding: 4% 6%;
  background-color: #fff;
  margin: 0 1%;
  flex-direction: column;
  border-radius: 4px;
}
.roles-and-permission-radio-btn {
  width: 98% !important;
}
.roles-and-permission-radio-btn label {
  font-size: 0.9rem !important;
}
.roles-and-permission-heading-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.roles-and-permission-heading-container h3{
  width: fit-content;
}
.roles-and-permission-heading-container button.btn-update-roles-and-permission{
  width: 30%;
  height: 36px;
  margin: 0 0 0 1% !important;
}
.roles-and-permission-designation-top-container{
  position: relative;
}

.roles-and-permission-validation{
  top: 70% !important;
  font-weight: 600 !important;
}
.roles-and-permission-designation-top-container div.MuiOutlinedInput-root {
  background-color: #1100ff;
  width: 100%;
  height: 40px;
  /* margin: 6% 0; */
  color: #fff;
  font-size: 0.9rem;
  border: none;
}
.roles-and-permission-designation-top-container .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  background-color: #1100ff;
  color: #fff;
  
}
.roles-and-permission-designation-top-container .MuiAutocomplete-endAdornment button {
  color: #fff; /* Change the color to your desired color */
}
.roles-and-permission-designation::placeholder{
  color: #fff;
}
.roles-and-permission-designation-second-container p {
  margin: 4% 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.roles-and-permission-employee-top-container {
  position: relative;
  margin: 6% 0;
}
.roles-and-permission-employee-top-container input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgb(202, 200, 200);
  padding: 0 2%;
  font-size: 0.9rem;
}
.roles-and-permission-employee-top-container input::placeholder {
  font-size: 0.8rem;
}
.roles-and-permission-employee-top-container .roles-and-permission-search-icon {
  position: absolute;
  background-color: #eee;
  border-radius: 0px;
  right: 2%;
  height: 31px;
  top: 11%;
  width: 12%;
}
.roles-and-permission-employee-radio-btn-container {
  display: flex;
  flex-direction: column;
  height: 87%;
  overflow-y: auto;
}
.roles-and-permission-employee-radio-btn-container::-webkit-scrollbar {
  width: 2px;
  background-color: #f1f1f1;
}
.roles-and-permission-employee-radio-btn-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
.roles-and-permission-employee-radio-btn {
  margin: 2% 0;
}
.roles-and-permission-employee-radio-btn input {
  cursor: pointer;
}
.roles-and-permission-employee-radio-btn input:checked {
  accent-color: #1100ff;
}
.roles-and-permission-employee-radio-btn label {
  font-size: 0.9rem;
  margin: 0 1%;
  cursor: pointer;
}

.roles-and-permission-right-container h3 {
  margin: 1% 0;
}

.roles-and-permission-single-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.roles-and-permission-single-input p {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 2% 0;
}
.roles-and-permission-multiple-input p {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 1%;
}
.roles-and-permission-multiple-input
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}
.roles-and-permission-multiple-input
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0;
}

.roles-and-permission-multiple-input .css-o4b71y-MuiAccordionSummary-content {
  margin: 0;
}
.roles-and-permission-multiple-input .roles-and-permission-multiple-input {
  color: #000;
  width: 2rem;
}
.accordion-multiple-input {
  width: 80% !important;
  margin-left: 12px;
}
.accordion-multiple-input p {
  margin: 5% 0;
  font-size: 0.8rem;
}

/* accordion with sub menu */
.roles-and-permission-multiple-sub-input
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  margin-left: 10px !important;
}
.roles-and-permission-multiple-sub-input p {
  font-size: 0.8rem;
}

.toggle-checkbox {
  /* margin: 16.8px 0; */
  /* -webkit-transition: .8s; */
  transition: 0.4s;
}
input.toggle-checkbox {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 40px;
  height: 20px;
  background-color: #ce001c;
  border: 1px solid #be0019;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #ffa1ae;
  cursor: pointer;
}

input.toggle-checkbox:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  background: transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.toggle-checkbox:checked {
  background-color: #00ce67;
  box-shadow: inset 20px 0 0 0 #b1fbd6;
  border-color: #2acd7c;
}

input.toggle-checkbox:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
