.stock-report-print-download-icon-container{
    display: flex;
    justify-content: center;
    margin: 1% 2%;
    align-items: center;
    flex-wrap: wrap;
}
.stock-report-total-paid-ctn{
    background-color: #e5ebff;
    color: #1166ff;
    display: flex;
    height: 36px;
    width: 20%;
    max-width: 170px;
    margin: 1%;
    border-radius: 4px;
    align-items: center;
    padding: 1%;
    justify-content: space-between;
}
.stock-report-table-multiple-td{
    width: 1%;
    border-bottom: none !important;
}

/* stock adjustment css */
.stock-adj-status-pending{
    background-color: #e79d9d;
    color: #ca120f;
    font-size: 0.75rem;
    border-radius: 4px;
    width: 77%;
    margin: 2% auto;
    
}
.stock-adj-status-complete{
    background-color: #dfce89;
    color: #ac9f65;
    font-size: 0.75rem;
    border-radius: 4px;
    width: 77%;
    margin: 2% auto;
}
.stock-adj-status-confirm{
    background-color: #89b1df;
    color: #4260d6;
    font-size: 0.75rem;
    border-radius: 4px;
    width: 77%;
    margin: 2% auto;
}
.stock-adj-status-drafted{
    background-color:#fdddca;
    color: #ff8e42;
    font-size: 0.75rem;
    border-radius: 4px;
    width: 77%;
    margin: 2% auto
}
.stock-adj-report-view-container{
    width: 62vw;
    padding: 2% 4%;
}
.stock-adj-report-view-input-container{
    display: flex;
    flex-wrap: wrap;
    box-shadow:0 0 10px 4px #eee ;
}
.stock-adj-report-view-table-container{
    box-shadow:0 0 10px 4px #eee ;
    padding: 1% 0;
    margin: 4% 0;
}
.stock-adj-report-view-table-container h4{
    margin: 2%;
}
.stock-adj-report-view-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.stock-adj-report-view-btn-container button{
    background-color: #ffaa00 !important;
}

.stock-report-stock-price-container{
    background-color:  #ffe5e5;
    color: #ff2d17;
    margin: 1%;
    height: 45px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 8px 12px;
    min-width: 10.5%;
}
.stock-report-stock-price-container p{
    margin: 0;
    font-size: 0.7rem;
}
.total-sales-price{
    background-color:  #e5ebff;
    color: #1166ff;
}
.total-closing-stock-price{
    background-color:    #f5e5ff;
    color:  #a200ff;
}
.total-sale-container{
  background-color: rgba(248, 248, 185, 0.884); 
  color:rgba(238, 185, 10, 0.753);  
}
.total-closing-stock-container{
    background-color: rgb(253 221 202);
    color:rgb(255 142 66);
}
.total-stock-container{
    color: #944200;
    background-color: #f5decc;
}
.stock-report-admin-view-top-container{
    background-color: #e5ebff;
    display: flex;
    padding: 1% 2%;
    justify-content: space-between;
    align-items: center;
}
.stock-report-admin-view-top-container-right{
    display: flex;
    justify-content: space-around;
}
.stock-report-admin-view-top-container-right h4{
    margin:0;
}
.print-table-total-tr-container{
    height:45px;
    border-bottom: 1px solid #000 !important;
    border-top: 1px solid #000 !important;

}
.stock-report-radio-ctn{
    position: absolute;
    display: flex;
    top: 100%;
    left: 69%;
    width: 20%;
}
.stock-report-radio-group{
    display: flex;
}
.global-table tr .print-view-fixed-two-right{
    text-align: right !important;
    padding-right: 3px;
}

.stock-report-radio-group input[type="checkbox"]{
    accent-color: #fff;
}