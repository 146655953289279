.special-items-head{
    padding:15px 25px;
}
.special-items-head h3{
    margin: 0;
}
.special-items-ctn .lists{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px calc((100% - 85%) / 3);
    padding-top: 10px;
}
.special-items-ctn .list-cards{
    width: 30%;
    background-color: #fff;
    border-left: 10px solid #ffd1b9;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 15px;
    border-radius: 3px;
    box-shadow: 1px 2px 5px 1px #e8e8e8;
}
.special-items-ctn .list-cards .head{
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.special-items-ctn .list-cards .justify-space-between{
   border-bottom: 2px dashed #c9c1c1;
}
.special-items-ctn .list-cards .btn-ctn{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    width: 150px;
}

.special-items-ctn .list-card-items{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 13px;
    cursor: pointer;
    min-height: 160px;

}
.special-items-ctn .list-card-items p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.special-items-ctn .lists .disabled-lists p{
   color: rgba(128, 128, 128, 0.403);
}

.list-card-items .list-selected{
    text-decoration: line-through;
    font-weight: 400;
   color: rgba(128, 128, 128, 0.403);

}
.p-head{
    margin: 0;
    font-weight: 600;
    font-size: 0.9rem;
}

.special-items-ctn .new-global-single-input{
    margin: 8px 0;
}
.special-items-ctn .new-global-single-input div.MuiOutlinedInput-root {
    height: auto!important;
}

.new-global-single-input input.MuiOutlinedInput-input{
    padding: 9px;
}

.special-items-table table tbody tr td{
    border: none;
    padding-top: 9px;
} 
.special-items-table table tbody tr td:hover{
    border: 2px solid #dee3f8;
} 

.special-items-table input{
    text-align: center;
    height: auto!important;
}

.combo-tag-ctn{
    position: relative;
    width:90px;
    display: flex;
    justify-content: center;
}
.combo-tag-ctn img{
    width:100%
}
.combo-tag-ctn p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 0.75rem;
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
    margin: 0;
}