.accounts-configuration-ctn{
    display: flex;
    flex-direction: column;
}
.configuration-single-input{
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: 6px;
    flex-direction: row!important;
    align-items: center;
}
.configuration-single-input .doc-validation-alert{
    top: 77%;
}
.configuration-single-input .MuiAutocomplete-root{
    width: 70%;
}
.accounts-configuration-ctn p.head{
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin: 3px 0 !important;
}
.accounts-configuration-ctn p{
    font-size: 0.9rem;
    font-weight: 500;
    margin: 1% 0;
    width: 30%;
}