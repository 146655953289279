.staff-add-payroll-container{
    width: 60vw;
}
.staff-add-payroll-top-ctn{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 3%;
}
.staff-add-payroll-top-ctn h3{
    margin: 1%;
}
.staff-add-payroll-top-ctn-input-field{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.add-payroll-input{
    flex-basis: 30%;
}
.staff-add-payroll-top-ctn-btn-ctn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin:2%;
}
.staff-add-payroll-body-ctn{
    display: flex;
    gap: 1rem;
    margin-top: 2%;
}
.staff-add-payroll-body-container-parent{
    width: 50%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.staff-add-payroll-body-section-input-field input{
    text-align: right;
    padding: 2% 20%;
}
.staff-add-payroll-body-section{
    width: 100%;
    background-color: #fff;
    padding: 1% 3%;
}
.staff-add-payroll-body-section-bottom-ctn{
    padding: 2% 0;
}

.payroll-body-section-bottom-ctn-extra{
    padding: 2% 0% 2% 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.payroll-body-section-bottom-ctn-extra .global-single-input{
    width: 40%;
    margin-right: 0;
}
.payroll-body-section-bottom-ctn-extra input,.staff-add-payroll-body-section-footer input{
    text-align: right;
    padding: 2% 20%;
}
.payroll-body-section-bottom-ctn-extra p{
    margin: 0 0 6px 0;
    font-size: 0.8rem;
    font-weight: 600;
}
.staff-add-payroll-body-section-footer{
    padding: 0% 2% 0% 10%;
    display: flex;
    background-color: #e7e7e7;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
}
.staff-add-payroll-body-section-footer .global-single-input{
    width: 30%;
    margin-right: 0;
    background-color: white;
    border-radius: 5px;
}
.staff-add-payroll-body-section-footer .global-single-input input{
    background-color: #fff;
    height: 30px;
}
.staff-add-payroll-body-section-footer p{
    font-weight: 600;
    font-size: 0.8rem;
}
.staff-add-payroll-body-section-input-two{
    display: flex;
}


.staff-add-payroll-footer-ctn{
    width: 60vw;
    background-color: #fff;
    margin: 2% 0;
    padding: 3%;
    display: flex;
    justify-content: flex-end;
}
.staff-add-payroll-footer-ctn-section{
    background-color: #edeef0;
    width: 66%;
    padding: 1% 3%;
}

.staff-add-payroll-footer-ctn-left-section{
    flex-direction: row !important;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 2% 1%;
}
.staff-add-payroll-footer-ctn-left-section input{
    width: 40%;
    background-color: #fff;
    text-align: right;
    padding: 2% 5%;
}
.staff-add-payroll-footer-ctn-right{
    width: 40%;
    text-align: right;
}
.staff-add-payroll-footer-ctn-right h4{
    margin: 1%;
}
.staff-add-payroll-footer-ctn-right p{
    margin: 1%;
}
.staff-add-payroll-button-ctn{
    display: flex;
    justify-content: flex-end;
    margin: 4% 0;
}

/* Dialog payment */
.staff-payroll-payment-ctn{
    width: 40vw;
    padding: 2% 4%;
}

.staff-payroll-payment-ctn-input-field{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 3%;
}
.staff-payroll-payment-ctn-input-field .global-single-input{
    flex-basis: 45%;
}
.staff-payroll-payment-ctn-buttons{
    width: 100%;
    margin: 3% 0%;
    display: flex;
    justify-content: center;
}
.staff-payroll-payment-ctn-buttons .btn{
    font-size: 0.8rem;
}
