.payroll-emp-salary-top{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1%;
}
.payroll-emp-salary-top-right{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.payroll-emp-salary-top-right .btn-new-payroll{
    width: 45%;
    background-color: #3355ff;
    font-size: 0.9rem;
}

.payroll-emp-salary-top-right .btn-download-payroll{
    width: 45%;
    background-color: #ff9933;
    font-size: 0.9rem;
    margin-right: 0;
}
.payroll-emp-salary-top-right .btn-new-payroll:hover{
    background-color: #0026e6;
}

.payroll-emp-salary-top-right .btn-download-payroll:hover{
    background-color: #ff8000;
}


.payroll-emp-salary-table .btn-approved{
    color: #3da225;
    font-size: 0.7rem;
    height: 30px;
    width: 60%;
    background-color: #d4e3d3;
}
.payroll-emp-salary-table .btn-rejected{
    color: #ff9100;
    font-size: 0.7rem;
    height: 30px;
    width: 60%;
    background-color: #f7cda2;
}



/* PaySlip */
/* PaySlip */
/* PaySlip */


.payroll-payslip-container{
    width: 60vw;
    background-color: #fff;
}
.payroll-payslip-container-header{
    display: flex;
    padding: 1% 5%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(166, 166, 166, 0.8);
}
.payroll-payslip-container-header-right{
    display: flex;
    width: 25%;
    align-items: center;
}
.payroll-payslip-container-header-right-close{
    font-size: 1.5rem;
    color: rgb(100, 100, 100);
    cursor: pointer;
    background-color: transparent;
}
.payroll-payslip-container-header-right-close:hover{
    background-color:  rgb(100, 100, 100);
    color: #fff;
}
.payroll-payslip-container-header-right .btn-payslip-red{
    background-color: rgb(252 204 204);
    width: 45%;
}
.payroll-payslip-container-header-right .btn-payslip-blue{
    width: 45%;
    background-color: rgb(204 207 255);
}

.payroll-payslip-container-body{
    padding: 1% 5%;
}
.payroll-payslip-container-body-top-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 400;
}
.payroll-payslip-container-body-table{
    border: 1px solid rgb(229 229 229);
}


 .payroll-payslip-container-body-table{
    display: flex;
    flex-direction: column;
    
}
.payroll-payslip-container-body-table-container{
    display: flex;
    width: 100%;
}
.payroll-payslip-container-body-table-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(229 229 229);
    border-left: none;
}
.payroll-payslip-container-body-table-section-top{
    padding: 0 5%;
}
.payroll-payslip-container-body-table-section-body{
    display: flex;
    width: 100%;
    height: auto;
}
.payroll-payslip-container-body-table-section-body-left{
    width: 65%;
    border-right: 1px solid rgb(229 229 229);
}
.payroll-payslip-container-body-table-section-body-right{
    width: 35%;
}
.payroll-payslip-container-body-table-section-body-left-top,.payroll-payslip-container-body-table-section-body-right-top{
    padding: 0% 5%;
    border: 1px solid rgb(229 229 229);
    border-left: none;
    border-right: none;
}
.payroll-payslip-container-body-table-section-body-left-total,.payroll-payslip-container-body-table-section-body-right-total{
    border-top: 1px solid rgb(229 229 229);
    padding: 0% 5%;
}
.payroll-payslip-container-body-table-section-body-left-body,.payroll-payslip-container-body-table-section-body-right-body{
    padding: 0% 5%;
    min-height: 32vh;
}


.payroll-payslip-container-body-total{
    display: flex;
    margin: 2% 0;
    border: 1px solid rgb(229 229 229);
    width: 100%;
}
.payroll-payslip-container-body-total-section{
    padding: 0 2%;
    width: 20%;
}
.payroll-payslip-container-body-total-section p{
    font-size: 0.8rem;
    margin-top: 0;
    font-weight: 600;
    color: #7f7f7f;
}

.payroll-payslip-container-body-footer{
    width: 100%;
    text-align: center;
    border: 1px solid rgb(229 229 229);
    margin-bottom: 2%;
}
.payroll-payslip-container-body-footer p{
    font-size: 0.8rem;
    font-weight: 600;
}


/* updates part3 */
.payroll-emp-top-container{
    background-color: #fff;
    padding: 3%;
   display: flex;
   flex-direction: column;
} 
.payroll-emp-top-container h3{
    margin: 0;
}
.payroll-emp-salary-container{
    padding: 3%;
}
.payroll-emp-top-container-input-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.payroll-emp-top-container-button{
    align-self: center;
    margin-top: 3%;
}
.staff-add-payroll-body-section .global-single-input{
    width: 100%;
}
.add-payroll-body-earning-p{
    color: #05b761;
    font-weight: 600;
}
.payroll-payslip-container-body-table-section-body-row{
    padding:0px 10px;
    margin: 10px 0;
}

.staff-payroll-edit-bottom-container{
    display: flex;
    justify-content: center;
    margin: 3% 0;
}