.card-type-container{
    display: flex;
}
.card-type-left-menu-container{
    flex: 40%;
    background-color: #fff;
    border-radius: 8px;
    padding: 3% 0;
    height: 85vh;
}
.card-type-right-menu-container{
    flex: 60%;   
}
.card-type-left-menu-button{
    width: 100%;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 6% 8px 6% 6%;
    margin: 12px 0;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}
.card-type-menu-disabled{
    cursor: not-allowed;
    color:#ddd9d9;

}
.card-type-menu-disabled:hover{
   background-color: transparent !important;
}
.card-type-left-menu-button ul li{
    font-weight: 600;
    font-size: 0.9rem;
}
.card-type-left-menu-button:hover{
    background-color: #f2f3f9;
}

.focus-device{
    background-color: #ebeefe;
}

/* card manual */
.card-manual-container{
   display: flex;
   flex-direction: column;
   margin: 0 2%;
}
.card-manual-top-container{
    background-color: #fff;
    padding: 2%;   
    border-radius: 8px;
   
    display: flex;
}
.card-name-container{
    width: 30%;
}
.card-type-logo-container{
    width: 35%;
}
.card-type-logo{
    position: relative;
    width: 100%;
}
.card-type-logo input[type="file"]{
    opacity: 0;
}
.card-type-logo label{
    position:absolute;
    right:0;
    top:0;
    background-color: blue;
    width: 34%;
    height: 40px;
    color:#fff;
    display:flex;
    align-items: center;
    justify-content:center;
    font-Size:1.5rem;
    cursor:pointer
}
.card-type-logo label img{
    width: inherit;
}
.card-manual-button{
    width: 25%;
    align-self: flex-end;
}
.card-manual-table-container{
    background-color: #fff;
    padding: 2%;   
    border-radius: 8px;   
}

/* card auto */
.card-auto-single{
    width: 99% !important;
    margin: 4% 4% 8% 4%;
}


/* .Manual{
    border: 2px solid orange;
} */