.home-dashboard-new-container {
  padding: 0.5em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.home-dash-new-wrapper {
display: flex;
flex-wrap: wrap;
width: 100%;
justify-content: center;



  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  overflow: hidden;

  grid-gap: 10px;
  transition: all 0.3s ease-in-out;
}
.home-dash-new-wrapper >div{
  flex: 1 0 49%;
}

.home-dash-temp-cntr {
    background-color: white;
    padding: 2em;
    border-radius: 10px;


}
.home-dash-graph-head{
    text-align: center;
    padding: 0.5em;
    text-transform: capitalize;

}

.sub-act-container{
    padding: 1em 0;
    display: flex;
    gap: 0.5em;
}
.dash-flow-chart-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* make responsive */
@media screen and (max-width: 1550px) {
  .home-dash-new-wrapper >div{
    flex: 1 0 47%;
  }
  /* .flw-style-3_1 {
    min-width: 5.3em !important;
  }

  .home-dash-new-wrapper >div:nth-child(5){
   min-width: 500px;
  }
  .home-dash-new-wrapper >div:nth-child(6){
   min-width: 500px;
  } */

}