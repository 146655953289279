.top-clickable-path{
    display: flex;
    gap: 10px;
    align-items: center;
}
.top-clickable-path p{
    margin: 0;
    cursor: pointer;
}

.tab-groups{
    display: flex;
    background-color: #e7e7e7;
    padding: 7px 15px;
    gap: 10px;
    font-size: 0.9rem;
    font-weight: 600;
}
.tab-groups .tab-buttons{
    padding: 2px 10px;
    border-right: 1px solid #8d8d8d;
    cursor: pointer;
}
.tab-groups .tab-buttons:hover{
    opacity: 0.5;
}
.two-input-ctn{
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 1%;
}
.two-input-ctn .global-single-input{
    width: 48%;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}
.two-input-ctn .global-single-input p{
    font-size: 0.9rem;
    white-space: nowrap;
    width: 30%;
    max-width: 160px;
    margin: 0;
}
.two-input-ctn .global-single-input input{
    width: 60%;
}


/* batch popup */
.table-batch-icon-ctn{
    background-color: transparent;
    border: 0.5px solid #000;
    border-radius: 5px;
}
.table-batch-icon-ctn-input{
    background-color: transparent !important;
    border:none !important;
}
.batch-save-popup-ctn{
    width: 70vw;
    max-width: 900px;
}
.batch-save-popup-topbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    border-bottom: 2px dashed #bbbbbb;
}

.batch-save-popup-body{
    padding: 0 25px;
    margin-bottom: 15px;
}

.batch-product-info{
    display: flex;
    flex-direction: row;
}

.batch-product-info div{
    padding: 15px 0;
}
.batch-product-info div:nth-child(1){
    width: 90px;
}
.batch-product-info div p{
    margin: 5px;
    font-weight: 600;
    margin-bottom: 0;
}

.batch-save-popup-body table td input{
    padding: 5px 0;
    text-align: center;
}
.batch-save-popup-body .global-product-table{
    margin: 15px 0;
}
.batch-save-popup-body .global-product-table td{
    border: none;
    border-right: 2px solid #e7e7e7;
    border-bottom: 1px solid #dee3f8;
}
.batch-save-popup-body .global-product-table th{
    font-size: 0.9rem;
}
.batch-save-popup-body .global-product-table td:last-child{
    border-right: none;
}
.add-line{
    margin: 0;
    cursor: pointer;
    color: #5d58ff;
    font-weight: 600;
    font-size: 0.9rem;
    width: fit-content;
}