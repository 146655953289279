.products-info-container{
    flex-wrap: wrap;
    justify-content: space-between;
}
.products-info-container .global-single-input{
    width:48%
}
.inv-products-top-section{
    display: flex;
    flex-direction: column;
}

.products-switch-menu{
    display: flex;
    justify-content: start;
    gap: 15px;
    margin-top: 15px;
    border-bottom: 3px solid #d6dafc;
}
.products-switch-menu div{
    width: 16%;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    padding: 3px;
    padding-top: 6px;
    color: rgb(109 106 106);
}
.products-switch-menu div.selected{
    background-color: #d6dafc;
    border-radius: 15px 15px 0px 0px;
    color: #000;
}
.products-switch-menu div:not(.selected):hover{
    background-color: #eff1ff;
    border-radius: 15px 15px 0px 0px;
    color: #a7a1a1b8;
}

.isFabric-class{
    display: flex;
    /* width: 55%; */
    align-items: center;
    margin-top: 24px;
  
}
.isFabric-class input{
    accent-color: #49d0e2;
    cursor: pointer;
    margin-right: 8px;
    height: 17px;
    width: 17px;
}
.isFabric-class label{
    color: rgb(97, 196, 235);
    font-size: 0.9rem;
    font-weight: 600;
    margin-right: 8px;
   
}

.fabric-details-icon{
    font-size: 1rem;
    color: rgb(97, 196, 235);
    margin-right: 12px;
    cursor: pointer;
}

.fabrics-details{
    width: 50%;
    font-size: 0.9rem;
    font-weight: 600;
    color:rgb(97, 196, 235);
    display: none;
    min-width: 270px;
    margin: 4% 0 0 0;
   
}
.attribute-multiple-auto-complete input{
    min-width: 50px !important;
}
.attribute-multiple-auto-complete button.MuiAutocomplete-clearIndicator{
    display: none;
}
.attribute-multiple-auto-complete div.MuiOutlinedInput-root{
    height: auto !important;
    padding-right: 0px !important;
}
  /* .fabric-details-icon:hover + .fabrics-details {
    display: block;
  }
  .bi.bi-question-circle.fabric-details-icon:hover + .fabrics-details {


    display: block;
  } */
