.utility-stock-corrections-container{
    background-color: #fff;
    padding:1%;
    border-radius: 8px;
    margin: 1% 0;
    justify-content: center;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
}
.utility-stock-corrections-container-top{
    display: flex;
    align-items: center;
    width: 70%;
    padding-left: 20px;
    font-size: 1.2rem;
    font-weight: 600;
}
.utility-stock-corrections-container-top .global-single-input{
    width: 30% !important;
}
.utility-stock-corrections-container-top p{
    margin-right: 40px;
}
/* DialogBox */

.stock-correction-action-parent{
    padding: 4%;
    width: 40vw;
    display: flex;
    flex-direction: column;
}
.stock-correction-top{
    font-weight: 600;
    color: #000;
    border-bottom: 2px solid #c3c3c3;
}
.stock-correction-body{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2% 0;
}
.stock-correction-body-section{
    display: flex;
    flex-direction: column;
    width: 45%;
}
.stock-correction-bottom{
    width: 100%;
    display: flex;
    justify-content: center;
}