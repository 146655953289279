.discount-settings-container{
    display: flex;
    
}
.discount-settings-container-body{
    display: flex;
    flex-direction: column;
    width: 55%;
}
.discount-settings-container-body-section{
    display: flex;
    width: 100%;
    padding: 40px 30px;
    background-color: #fff;
    margin: 0 1%;
    flex-direction: column;
    border-radius: 4px;
}
.discount-settings-container-body p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.discount-settings-container-body input{
    width: 98%;
    height: 60px;
    margin: 2% 0;
    background-color:#f6f6f6;
    border: none;
    font-size: 1rem;
    padding: 0 16px;
}
.discount-settings-container-button{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 2%;
}
.discount-settings-container-button button{
    width: 20%;
}