.manage-employee-container {
  background-color: #fff;
  padding: 2%;
}
.manage-employee-top-container {
  display: flex;
  justify-content: space-between;
}
.manage-employee-top-container h3 {
  margin: 0;
}
.manage-employee-top-container div.MuiOutlinedInput-root {
  width: 20%;
  height: 40px;
  background-color: #ff6433;
  border: none !important;
  color: #fff;
  font-size: 0.8rem;
}
.manage-employee-table {
  margin: 4% 0 !important;
  padding: 0 !important;
}
.emp-table-img{
  width: 48px ;
  height: 48px ;
  border-radius: 50%;
}
.manage-employee-table-active-emp{
  background-color: #b8deb1;
  color: #00bb0f;
  padding: 2px 5px;
  border-radius: 2px;
}
.manage-employee-table-inactive-emp{
  background-color: #efa791;
  color: #e7fde9;
  padding: 2px 5px;
  border-radius: 2px;
}