.shift-report-table-container{
    height: 73vh;
    overflow-y: auto;
    position: relative;
}

.shift-report-table-container::-webkit-scrollbar{
    width: 8px;
    background-color: #91a2ac;
}
.shift-report-table-container::-webkit-scrollbar-thumb{
    background-color: #a6b9c4;
}

.report-totalbar{
    display: flex;
    background-color: #b7b5b5;
    margin-top: 10px;
    position: absolute;
    width: 100%;
    padding: 0 1%;
    top: 93%;
    z-index: 20;
}
.report-totalbar tfoot{
    background-color: #b7b5b5;
    height: 60px;
}
.report-totalbar table{
    background-color: #b7b5b5;
}
.report-totalbar thead{
    height: 0!important;
    opacity: 0;
    top: 25px !important;
}
.report-totalbar tfoot td{
    text-align: center;
}